:root {
    --black: #0D151C;
    --black-2: #050D14;
    --black-pearl: #233645;
    --orange: #E9530D;
    --primary: #E9530D;
    --green: #47A58E;
    --gray: #95A2AD;
    --light-gray: #97A2AC;
    --blue: #1C6C89;
    --bg-second: #343434;
    --white: #ffffff;
    --fz: 16px;
    --font-main: "Urbanist", sans-serif;
    --sector-color: var(--primary);
    --aos-distance: 50px;
    --vh: 9px;

    /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
    --mobile-menu-start-point: 640;
    --sdv-mobile-breakpoint: 992; /*this and custom value should be the same*/
    --become-sc-mobile-breakpoint: 992; /*this and custom value should be the same*/
}

@custom-media --sdv-mobile-breakpoint (max-width: 992px); /* this and root value should be the same*/
@custom-media --become-sc-mobile-breakpoint (max-width: 992px); /* this and root value should be the same*/

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 640px);


/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 641px);

@keyframes rotareScrollLabel {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulseDown {
    0% {
        transform: translate(-50%, -50%);
    }
    100% {
        transform: translate(-50%, calc(-50% + 5px));
    }
}

.scroll-bar-style {
    scrollbar-color: var(--primary) var(--gray);
    scrollbar-width: 5px;

    &::-webkit-scrollbar {
        width: 4px;
        height: 2px;
        background: var(--gray);
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--primary);
        border-radius: 3px;
        cursor: pointer;
    }
}

.clip-title{
    opacity: 0;
}

.clip-down {
    opacity: 1;

    span {
        line-height: 98%;
        clip-path: polygon(0 100%, 100% 0, 100% 0, 0 100%);
        display: inline-block;
        animation: clip-path-down 600ms 0.5s ease-out forwards;
        opacity: 0;
        transform: translateY(-15px) rotate(-3deg);
        &:nth-of-type(1){animation-delay: 500ms;}
        &:nth-of-type(2){animation-delay: 400ms;}
        &:nth-of-type(3){animation-delay: 300ms;}
        &:nth-of-type(4){animation-delay: 200ms;}
        &:nth-of-type(5){animation-delay: 100ms;}
        &:nth-of-type(6){animation-delay: 0ms;}
    }
}


@keyframes clip-path-down {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        clip-path: polygon(0 0, 100% 0, 100% 120%, 0 120%);
        transform: translateY(0px) rotate(0deg);
    }
}
