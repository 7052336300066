.clients-page {
    .hero-section-caption-wr {
        padding-bottom: ac(186px, 26px);
    }

    .mission-section {
        margin-top: ac(-163px, -50px);
    }

    .sdv-vertical-section {
        padding-top: ac(155px, 50px);
    }

    .sdv-vertical-body {
        @media (min-width: 1200px) {
            gap: ac(228px, 100px, 1200, 1440);
        }
    }

    .work-with-section {
        @media (min-width: 1200px) {
            padding-top: 100px;
        }
    }
    .partners-section{
        padding-bottom: ac(80px, 30px);
    }
    .footer{
        padding-top: ac(94px, 35px);
    }
}

.case-studies-swiper-section {
    padding: 41px 0 ac(62px, 30px);

    .title-wr {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        margin-bottom: ac(30px, 20px);
        .btn{
            align-self: flex-end;
        }
    }

    .title {
        padding-bottom: ac(9px, 16px);
        margin-bottom: 0;
    }
}

.case-studies-swiper-wr {
    position: relative;
    padding-bottom: 54px;

    .swiper-slide {
        height: auto;
        display: flex;
        max-width: fit-content;
        width: 100%;
    }

    .swiper {
        overflow: visible;
        position: relative;
        @media (min-width: 641px) {
            &::before {
                content: '';
                display: block;
                position: absolute;
                right: calc(100% + 2px);
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: var(--black);
            }
        }
    }

    .case-study-card {
        width: 836px;
        max-width: 100%;
    }

}

.sdv-vertical-section.sdv-vertical-section-solutions {

    .sdv-vertical-item-thumbnail {
        display: none;
    }
}

.sdv-vertical-section-solutions-bg {
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);

    img {
        position: absolute;
        left: 0;
        top: 1px;
        width: 100%;
        height: calc(100% - 2px);
        object-fit: cover;
        opacity: 0;
        transition: opacity 0.5s;
        &.active{
            opacity: 1;
        }

    }
    picture img {

        animation-duration: 300ms;
    }

    .mixed-layer {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &:nth-of-type(1) {
            z-index: 2;
            background: linear-gradient(to right, rgba(13, 21, 28, 1), rgba(13, 21, 28, 0.65), rgba(13, 21, 28, 1));
        }

        &:nth-of-type(2) {
            z-index: 1;
            background: linear-gradient(to top, rgba(13, 21, 28, 1), rgba(13, 21, 28, 0.65), rgba(13, 21, 28, 1));
        }
    }
}