@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,300;0,500;0,700;1,400;1,700&display=swap');

/*---------- icomoon fonts ----------*/@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?sypinb');
    src:  url('../fonts/icomoon.eot?sypinb#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?sypinb') format('truetype'),
    url('../fonts/icomoon.woff?sypinb') format('woff'),
    url('../fonts/icomoon.svg?sypinb#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-email:before {
    content: "\e900";
}
.icon-chewron-down:before {
    content: "\e901";
}
.icon-chewron-up:before {
    content: "\e902";
}
.icon-chewron-left:before {
    content: "\e903";
}
.icon-chewron-right:before {
    content: "\e904";
}
.icon-arrow-right:before {
    content: "\e905";
}
.icon-arrow-down:before {
    content: "\e906";
}
.icon-arrow-left:before {
    content: "\e907";
}
.icon-arrow-up:before {
    content: "\e908";
}
.icon-arrow-down-long:before {
    content: "\e909";
}
.icon-arrow-down-right:before {
    content: "\e90a";
}
.icon-instagram:before {
    content: "\e90b";
}
.icon-linkedin:before {
    content: "\e90c";
}
.icon-twitter:before {
    content: "\e90d";
}

/*---------- end of icomoon fonts ----------*/