html{
	font-size: var(--fz);
	color: var(--white);
	background: var(--black);
	font-family: var(--font-main);
}


h1 {
	font-size: ac(80px, 55px);
	line-height: 1;
	margin-bottom: ac(18px, 10px);
}

h2 {
	font-size: ac(54px, 26px);
	margin-bottom: 14px;
}

h3 {
	font-size: ac(30px, 24px);
}

h4 {
	font-size: ac(28px, 22px);
	margin-bottom: ac(20px, 16px);
}

h5 {
	font-size: ac(26px, 21px);
	margin-bottom: ac(18px, 10px);
}

h6 {
	font-size: ac(24px, 20px);
}

