.job-post-page {
  .hero-section {
    @media (min-width: 768px) {
      min-height: ac(900px, 690px, 768, 1440);
      padding-bottom: ac(92px, 0px, 1024, 1440);
    }

    .hero-section-tags {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      span {
        margin-top: 10px;
        background-color: var(--black-pearl);
        padding: 0 ac(17px, 10px);
        font-size: ac(24px, 14px);
        line-height: ac(49px, 32px);
        margin-right: 10px;
        border-radius: 5px;
      }
    }

    .hero-section-social {
      display: flex;
      align-items: center;
      margin-top: ac(36px, 20px);
      @media (min-width: 1800px) {
        margin-right: 46px;
      }

      span {
        margin-right: 13px;
      }

      .social-btn {
        font-size: 18px;
        margin-right: 10px;
        color: var(--gray);
        transition: 0.3s ease;
        cursor: pointer;

        &:hover {
          color: var(--orange);
        }
      }
    }

    .hero-section-caption-wr {
      @media (min-width: 1440px) {
        max-width: 859px;
      }
      @media (min-width: 1800px) {
        max-width: 1100px;
      }
      @media (min-width: 1960px) {
        max-width: 100%;
      }

      @media (--mobile-menu-start-point) {
        justify-content: flex-end;
      }
    }
  }

  .job-post {
    &__post {
      position: relative;
      margin-top: ac(-209px, 80px);
      z-index: 1;
    }

    &__wrapper {
      display: flex;
    }

    &__col {
      padding-top: 13px;
      width: 100%;

      .teammate-card {
        width: 100%;

        .teammate-card-thumbnail {
          &::before {
            padding-top: 74.56%;
          }
        }

        .teammate-card-text {
          padding: 26px 26px 24px;

          p {
            font-size: 16px;
          }

          span {
            display: inline-block;
            margin-top: 16px;

            i {
              font-size: 19px;
            }
          }
        }

        &-info {
          @media (min-width: 1024px) {
            flex-direction: column;
            gap: 10px;
            align-items: flex-start;
          }
        }
      }
    }

    &__text-box {
      width: 100%;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: ac(158px, 50px, 1024, 1440);
      max-width: ac(694px, 500px, 1024, 1440);
    }

    &__text {
      width: 100%;
      color: var(--gray);
      *,
      span {
        font: inherit !important;
        line-height: 1.71!important;
        color: var(--gray) !important;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        position: relative;
        color: var(--gray);
        margin-bottom: ac(31px, 35px);

        &:not(:first-child) {
          margin-top: ac(35px, 23px);
        }

        &::before {
          content: "";
          position: absolute;
          bottom: -15px;
          left: 0;
          width: 50px;
          height: 6px;
          background-color: var(--orange);
        }
      }

      h1,
      h2 {
        color: var(--white);
      }

      h3 {
        font-size: ac(30px, 24px);
      }

      p {
        font-size: 14px;
        line-height: 1.71;

        &:not(:first-child) {
          margin-top: ac(10px, 0px);
        }
      }

      ul,
      ol {
        &:not(:first-child) {
          margin-top: ac(10px, 0px);
        }

        li {
          position: relative;
          padding-left: 15px;
          font-size: 14px;
          line-height: 23px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
          }
        }
      }

      ul {
        li {
          &::before {
            top: 9px;
            left: 0;
            width: 6px;
            height: 6px;
            border: 1.5px solid var(--gray);
            border-radius: 50%;
          }
        }
      }

      ol {
        counter-reset: my-counter;

        li {
          counter-increment: my-counter;

          &::before {
            content: counter(my-counter) ". ";
            color: var(--orange);
          }
        }
      }

      a {
        display: inline;
        font-size: inherit;
        line-height: inherit;
        text-decoration: underline;

        &:hover {
          color: var(--orange);
        }
      }
    }

    &__social {
      display: flex;
      align-items: center;
      margin-top: ac(36px, 20px);

      span {
        margin-right: 13px;
      }

      .social-btn {
        font-size: 18px;
        margin-right: 10px;
        color: var(--gray);
        transition: 0.3s ease;
        cursor: pointer;

        &:hover {
          color: var(--orange);
        }
      }
    }
  }

  .drop-cv-section {
    padding-top: ac(142px, 84px);

    .submit-btn {
      margin-left: auto;
    }

    .select-wr {
      &:nth-child(4) {
        border-right: 0;
      }
    }

    @media (max-width: 992px) {
      .drop-cv-form {
        flex-direction: column;
        padding: 28px 20px 22px;
        position: relative;

        .input-wr {
          border-right: 0;
          border-bottom: 1px solid var(--black-pearl);
          max-width: 100%;
          padding-bottom: 17px;
          margin-bottom: 14px;

          &:first-child {
            max-width: 100%;
          }
        }

        .select-wr {
          &:nth-child(4) {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
            min-height: 40px;
            width: calc(100% - 65px) !important;
          }
        }

        .submit-btn {
          width: 40px;
          height: 40px;
          bottom: 18px;
          position: absolute;
          right: 20px;
        }
      }
    }
  }

  .job-post {
    @media (max-width: 1023px) {
      &__wrapper {
        flex-direction: column;
      }

      &__text-box {
        max-width: unset;
        margin-right: 0;
      }

      &__col {
        padding-top: 40px;

        .teammate-card {
          max-width: 300px;
          margin: 0 auto;
        }
      }
    }
  }
}
