.job-search {

    .hero-section {
        background: var(--black);
        padding-top: 226px;
        padding-bottom: ac(100px, 90px);
        min-height: unset;
    }

    .hero-form-wr {
        width: 100%;
        padding-right: ac(55px, 0px, 768, 1440);

        .select-wr {
            &:nth-child(3) {
                border-right: 1px solid var(--black-pearl);
            }

            &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                width: 22%;
            }
        }
    }

    .hero-form {
        @media (min-width: 641px) {
            .input-wr {
                flex-grow: 1;
            }

            .select-wr {
                width: 26.4%;
                margin-right: 5px;
                border-right: none;
            }
        }
    }

    &__reset {
        margin-top: 28px;

        .select2-container .select2-selection--single .select2-selection__rendered {
            padding-left: 5px;
            padding-right: 16px;
        }

        .select2-selection__arrow {
            right: -1px !important;
        }
    }

    &__sort {
        margin-top: 17px;
        display: inline-flex;
        align-items: center;
        margin-left: auto;
        position: relative;

        & > span {
            font-size: 14px;
            line-height: 32px;
            margin-right: 10px;
        }

        .select2-selection--single {
            background-color: transparent;

            .select2-selection__rendered, .select2-selection__placeholder {
                color: var(--white);
                font-size: 14px;
            }

            .select2-selection__arrow {
                top: 11px;
                width: 7px;
                height: 7px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.083' height='6.577'%3E%3Cg fill='%23fff'%3E%3Cpath d='M3.041 0h1v6h-1z'/%3E%3Cpath d='m6.376 2.335.707.707-3.536 3.535-.707-.707z'/%3E%3Cpath d='m4.242 5.87-.707.707L0 3.04l.707-.707z'/%3E%3C/g%3E%3C/svg%3E") center / contain no-repeat;


                &::before {
                    display: none;
                }
            }
        }

        .select2-container--open {
            .select2-selection__arrow {
                transform: rotate(180deg);
            }
        }
    }

    .jobs-box {
        margin-top: ac(42px, 30px);

        &__wrapper {
            width: 100%;
            display: flex;
        }

        &__btn {
            flex: 0 0 auto;
            display: none;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            border-radius: 8px;
            overflow: hidden;
            background-color: var(--blue);
            cursor: pointer;
            transition: .3s ease;

            span {
                width: 15px;
                height: 12px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' fill='none'%3E%3Cpath fill='%23fff' d='M6.333 11.833h3.334v-1.666H6.333v1.666ZM3 6.833h10V5.167H3v1.666ZM.5.167v1.666h15V.167H.5Z'/%3E%3C/svg%3E") center / contain no-repeat;
            }

            &:hover {
                background-color: var(--green);
            }
        }

        &__close-btn {
            position: fixed;
            left: 0;
            bottom: 0;
            display: none;
            align-items: center;
            justify-content: center;
            height: 80px;
            width: 100vw;
            background-color: var(--black-pearl);
            transform: translateY(100%);
            z-index: 100;
            transition: .3s ease;

            span {
                width: 24px;
                height: 24px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23fff' d='M2.667 23.667.334 21.333 9.667 12 .334 2.667 2.667.333l9.334 9.334L21.334.333l2.333 2.334L14.334 12l9.333 9.333-2.333 2.334-9.333-9.334-9.334 9.334Z'/%3E%3C/svg%3E") center / contain no-repeat;
                cursor: pointer;
                transition: .3s ease;

                &:hover {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23E9530D' d='M2.667 23.667.334 21.333 9.667 12 .334 2.667 2.667.333l9.334 9.334L21.334.333l2.333 2.334L14.334 12l9.333 9.333-2.333 2.334-9.333-9.334-9.334 9.334Z'/%3E%3C/svg%3E") center / contain no-repeat;
                }
            }

            &.open {
                transform: translateY(0%);
            }
        }

        &__bottom {
            margin-top: auto;
            display: none;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        &__filter {
            width: 100%;
            max-width: 284px;
            margin-right: 50px;

        }

        &__form {
            .input-wr {
                width: 100%;
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                padding: ac(20px, 17px) ac(40px, 20px);
                font-size: 16px;
                border: 1.5px solid var(--light-gray);
                background-color: var(--white);
                border-radius: 10px;
                overflow: hidden;

            }

            input {
                width: 100%;
                letter-spacing: 0.25px;

                &::placeholder {
                    color: var(--gray);
                    opacity: 0.55;
                }
            }
        }

        &__accardion {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: -23px;
                left: 0;
                width: 40px;
                height: 4px;
                background-color: var(--orange);
            }

            &:not(:last-child) {
                margin-bottom: 40px;
            }

            &:last-child {
                &::before {
                    display: none;
                }
            }


        }

        &__accardion-title {
            margin-bottom: 8px;
            transition: .3s ease;
            width: fit-content;

            &:hover {
                cursor: pointer;
                color: var(--orange);
            }
        }

        &__accardion-content {
            border-left: 1px solid var(--light-gray);
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            label {
                position: relative;
                display: flex;
                align-items: center;
                padding-left: 12px;
                transition: .3s ease;
                cursor: pointer;


                span:last-child {
                    display: inline-block;
                    font-size: 13px;
                    margin-left: 7px;
                    margin-top: 2px;
                }


            }

            input[type='checkbox'] {
                position: absolute;
                visibility: hidden;
                opacity: 0;

                &:checked ~ .jobs-box__checkbox {
                    background: var(--orange);
                }
            }
        }

        &__checkbox {
            position: relative;
            width: 15px;
            height: 15px;
            border-radius: 2px;
            border: 1px solid var(--light-gray);
            &:hover{
                & + .jobs-box__label-name{
                    color: var(--orange);
                }
            }
        }

        &__job-box {
            width: 100%;

            .job-card {
                max-width: 100%;
                height: auto;
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }
    }

    @media (max-width: 1023px) {
        .hero-form-wr {
            .hero-form {
                padding: 28px 20px 22px;
                flex-direction: column;
                position: relative;

                .submit-btn {
                    width: 40px;
                    height: 40px;
                    bottom: 20px;
                    position: absolute;
                    right: 20px;
                }

                .input-wr, .select-wr {
                    border: none !important;
                    border-bottom: 1px solid var(--black-pearl) !important;
                    width: 100% !important;
                    max-width: unset !important;
                    padding-bottom: 17px;
                    margin-bottom: 14px;
                }

                .select-wr {
                    padding-left: 0;
                    width: calc(100% - 50px) !important;
                    margin-bottom: 0 !important;
                    padding-bottom: 0;

                    .select2-container--default .select2-selection--multiple {
                        padding-bottom: 17px;
                    }

                    .select2-container--open .select2-dropdown.select2-dropdown--below {
                        transform: translateY(1px);
                    }

                    &:nth-child(4) {
                        margin-bottom: 0;
                        padding-bottom: 8px;
                        border-bottom: 0;
                        width: calc(100% - 65px) !important;
                    }
                }

            }
        }

        .jobs-box {
            &__wrapper {
                flex-direction: column;
            }

            &__close-btn {
                display: flex;
            }

            &__btn {
                display: flex;
                margin-bottom: 20px;
            }

            &__filter {
                position: fixed;
                top: 0;
                left: 0;

                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 100vw;
                height: 100vh;
                max-width: unset;
                margin-right: 0;
                padding: 30px 34px 110px;
                background-color: var(--black);
                opacity: 0;
                visibility: hidden;
                transition: .3s ease;
                z-index: 100;
                transform: translateX(-100%);

                &.open {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0%);
                }
            }

            &__form {
                width: 100%;
                max-height: calc(100vh - 200px);

                .simplebar-content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }

                .simplebar-track {
                    right: -10px;
                }

                .simplebar-vertical {
                    width: 7px;

                    .simplebar-scrollbar {
                        &::before {
                            background-color: var(--white);
                            opacity: 1;
                        }
                    }
                }
            }

            &__bottom {
                display: flex;
            }
        }

    }

    @media (--mobile-menu-start-point) {
        .hero-section-caption {
            margin: 0;
        }
    }
}

.filter-label-wr {
    position: relative;
    .filter-toggle{
        width: 10px;
        height: 10px;
        position: relative;
        margin-right: 10px;
        cursor: pointer;
        --bg-color: var(--white);
        flex-shrink: 0;
        transition: 0.15s;

        &:hover{
            --bg-color: var(--orange);
            transform: scale(1.3);
        }
        &::before, &::after {
            content: '';
            display: block;
            background: var(--bg-color);
            transform-origin: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 0.3s;
        }
        &::before{
            width: 1px;
            height: 100%;
        }
        &::after{
            height: 1px;
            width: 100%;
        }
        & + input + .jobs-box__checkbox{
            margin-left: 0!important;
        }
    }
    ul{
        padding-left: 25px;
        display: none;
        label{
            padding-left: 0!important;
        }
        .jobs-box__checkbox{
            margin-left: 13px;
        }
    }
    label.active{
        .filter-toggle{
            &::before{
                opacity: 0;
            }
        }
    }
    .jobs-box__label-name{
        padding-left: 7px;
        margin: 0!important;
        transition: color 0.3s;
        &:hover {
            color: var(--orange);
        }
    }

}