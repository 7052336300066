.footer {
    padding-top: ac(92px, 38px);
    overflow: hidden;
    &-line{
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, var(--gray), var(--black-pearl));
        margin-bottom: ac(98px, 68px);
    }
    h4 {
        font-size: 14px;
        color: var(--white);
        margin-bottom: 19px;

        a {
            transition: 0.3s;

            &:hover {
                color: var(--orange);
            }
        }
    }

    &-link {
        color: var(--gray);
        position: relative;
        transition: 0.3s;
        font-size: 16px;
        opacity: 0.55;
        margin-bottom: 10px;
        letter-spacing: 0.35px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &::after {
            content: "\e90a";
            font-family: icomoon;
            font-size: 5px;
            padding-left: 11px;
            color: var(--orange);
            align-self: flex-end;
            padding-bottom: 4px;
        }

        &:hover {
            color: var(--orange);
            opacity: 1;
        }
        i{
            font-size: 18px;
            padding-right: 10px;
        }
    }

    &-copyright {
        color: var(--gray);
        font-size: 14px;
        display: flex;
        padding: ac(25px, 27px) 0;
        flex-direction: column;
    }

    &-navbar {
        display: flex;
        justify-content: space-between;
        padding-right: ac(45px, 0px);
    }

    nav {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        max-width: 668px;
    }
    &-social-box{
        padding-right: 25px;
    }

    &-locations {
        flex-grow: 1;
        max-width: 400px;

        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        li {
            width: 162px;
            max-width: 48%;
        }
    }

    .policy-box {
        padding-top: 42px;
        h4{
            margin-bottom: 13px;
        }
    }
}

@media (max-width: 768px){
    .footer{
        &-navbar {
            flex-wrap: wrap;
            nav {
                flex-wrap: wrap;
            }
        }
        &-social-box{
            margin-bottom: 38px;
        }
        &-locations{
            max-width: revert;
            width: 100%;
        }
        .policy-box{
            width: 100%;
            column-count: 2;
            column-gap: 15px;
            padding-top: 37px;
            padding-right: 6px;
        }
    }
  }
@media (--mobile-menu-start-point){
    .footer-copyright{
        flex-direction: column;
        line-height: 2.28;
    }
  }


@media (max-width: 370px){
    .footer-link{
        font-size: 13px;
    }
    .footer .policy-box h4{
        font-size: 12px;
    }
  }