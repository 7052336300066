#map {
	g[id$="-pin"] {
		display: none;
	}
	#DE-pin {
		display: none;
	}
	#US path {
		fill: #266da3;
	}
}
