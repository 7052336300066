.progress {
    &-section {
        padding: 80px 0;

        h2 {
            text-align: center;
            margin-bottom: 64px;
        }
    }

    &-chain {
        position: relative;
        max-width: 964px;
        margin: 0 auto;
    }

    &-item {
        max-width: ac(376px, 290px, 768, 1440);

        h4 {
            margin: 0;
        }

        p {
            padding: 0;
        }

        &:not(:last-of-type) {
            margin-bottom: ac(12px, 60px);
        }

        &:nth-child(odd) {
            text-align: right;
            transform: translateX(-100%);
        }

        &:nth-child(even) {
            margin-left: auto;
            transform: translateX(100%);
        }

        @mixin media 769 {
            max-width: 100%;

            &:nth-child(odd),
            &:nth-child(even) {
                margin-left: ac(75px, 30px, 375, 769);
                text-align: left;
                transform: translateX(50%);
            }
        }
    }

    &-line {
        width: 2px;
        height: 100%;
        position: absolute;
        background: var(--gray);
        top: 0;
        left: 50%;
        transform: translateX(-25%);
        overflow: hidden;

        .fill {
            width: 100%;
            height: 10%;
            background: var(--primary);
        }

        @mixin media 769 {
            left: 0;
            transform: none;
        }
    }
}

html {
    font-size: 16px;
    overflow-y: auto;
    scrollbar-color: var(--orange) var(--white);
    scrollbar-width: 6px;

    &::-webkit-scrollbar {
        width: 6px;
        height: 2px;
        background: var(--white);
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background:  var(--orange);
        border-radius: 3px;
        cursor: pointer;
    }
}
.text-center{
    text-align: center;
}

[data-aos="progress-anim"] {
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.5s ease;

    &.aos-animate {
        opacity: 1;
        transform: translateX(0);
    }
}

.fp-watermark {
    display: none;
}
img {
    transition: opacity 0.1s ease;
    &.lazyload {
        opacity: 0;
    }

    &.lazyloaded {
        opacity: 1;
    }
}

body {
    position: relative;
    min-width: 320px;
    margin: auto;
    overflow-x: hidden;

    &.disabled {
        overflow-y: scroll;
        position: fixed;
        width: 100%;
    }
}

.lg-hidden{
    @media (--mobile-menu-end-point){
        display: none!important;
      }
}
.mb-hidden{
    @media (--mobile-menu-start-point){
        display: none!important;
      }
}

.swiper-wrapper {
    left: 0 !important;
}

#site {
    position: relative;
    overflow-x: hidden;
}

.main {
    flex-grow: 1;
}
.bg-blue{
    background: var(--blue) !important;
}
.bg-orange{
    background: var(--orange) !important;
}
.bg-gray{
    background: var(--gray) !important;
}
.bg-green{
    background: var(--green) !important;
}
.bg-black{
    background: var(--black) !important;
}
.text-light-gray{
    color: var(--light-gray) !important;
}
.text-gray{
    color: var(--gray) !important;
}

%default-transition {
    transition: ease-in-out 0.25s;
}

.sector-energy {
    --sector-color: var(--blue)
}

.sector-tech {
    --sector-color: var(--green)
}

.sector-rail {
    --sector-color: var(--orange)
}

.sector-projects {
    --sector-color: var(--gray)
}


.cont {
    max-width: 1280px;
    width: perc(1160);
    padding: 0 20px;
    margin: 0 auto 0 12.5vw;
    @media (min-width: 1600px) {
        margin: 0 auto;
    }
}


section {
    position: relative;
}

#bold-credits {
    width: 150px;
    transition: ease-in-out 0.25s;
    display: block;

    &::before,
    &::after {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.sticky:before,
.sticky:after {
    content: "";
    display: table;
}

#site.cookie-terms-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    .main {
        flex-grow: 1;
        padding: 0;
    }
}

.cookie-terms-section {
    padding: ac(250px, 140px) 0 ac(150px, 50px);

    p {
        padding: 0;
        margin-bottom: 10px;
    }

    ul,
    ol {
        padding-left: 20px;

        li {
            p {
                margin-bottom: 0;
            }
        }
    }

    ul {
        list-style: circle;
    }

    ol {
        list-style: decimal;
    }

    a {
        display: inline;
        text-decoration: underline;
        transition: 0.2s;

        &:hover {
            opacity: 0.7;
        }
    }
}

.single-page-404 {
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
        font-size: ac(50px, 30px);
    }
    h1,
    p {
        margin-bottom: 30px;
        padding: 0;
    }

    a {
        display: inline-block;
    }
}

#container-test {
    &:container(width < 1200px) {
        background: orange;
    }
}

.scroll-down-label {
    position: absolute;
    left: 50%;
    transform: translate(0%);
    bottom: 122px;
    width: 80px;
    cursor: pointer;

    i {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 10px;

    }

    img {
        animation: rotareScrollLabel infinite 15s linear;

    }

    &:hover {

        i {
            animation: pulseDown 500ms infinite linear alternate;
        }
    }
}

.hero-section-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    picture{
        &::before{
            content: '';
            display:block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #000;
            mix-blend-mode: color;
            z-index: 1;
        }
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: calc(100% - 1px);
        object-fit: cover;
        z-index: 0;
    }
    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
        mix-blend-mode: color;
        filter: grayscale(1);
        @media (max-width: 450px){
            object-position: 29% bottom;
          }
    }


    &::before{
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: 1;
        background: radial-gradient(rgba(13, 21, 28, 0), rgba(5, 13, 20, 1));
    }

}
.sector-hero-section{
    .hero-sector-icons{
        position: absolute;
        right: 14%;
        top: 21.6%;
         display: block;
        width: fit-content;
    }
}
.sector-hero-section-bg{
    picture{
        &::before{
            display: none;
        }
    }
}
.sector-hero-section-bg{
    &::before{
        display: none;
    }
    div{
        position: absolute;
        inset: 0;
        &:nth-of-type(1){z-index: 5}
        &:nth-of-type(2){z-index: 4}
        &:nth-of-type(3){z-index: 3}
        &:nth-of-type(4){z-index: 2}
        &:nth-of-type(5){z-index: 1}
        &.hero-l-gradient{
            background: linear-gradient(to top, rgb(13, 21, 28), rgb(13, 21, 28, 0));
        }
        &.hero-black-layer{
            background: var(--black);
        }
        &.hero-r-gradient{
            background: radial-gradient(rgb(13, 21, 28, 0), rgb(13, 21, 28));

        }
        &.mx-color{
            mix-blend-mode: color;
        }
        &.mx-soft-light{
            mix-blend-mode: soft-light;
        }

    }
    img{
        z-index: 0;
    }
}

.sector-img-hover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }

    & > div {
        transition: opacity 0.3s;
        opacity: 0;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: inherit;
            mix-blend-mode: color;
        }

        &[data-sector='rail'] {
            background: var(--orange);
        }

        &[data-sector='projects'] {
            background: var(--gray);
        }

        &[data-sector='tech'] {
            background: var(--green);
        }

        &[data-sector='energy'] {
            background: var(--blue);
        }
    }
}

.show-hero-hover {
    z-index: 999;
    position: relative;
}

.title-marker {
    position: relative;
    padding-bottom: 16px;

    &::before {
        content: '';
        display: block;
        width: 50px;
        height: 5px;
        background: var(--primary);
        position: absolute;
        left: 0;
        bottom: 0;

    }

    &.title-marker-center {
        &::before {
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &-small{
        &::before{
            width: 40px;
            height: 4px;
        }
    }
}
.interior-page{
    .title-marker{
        padding-bottom: 9px;
    }
}

.hero-section {
    padding-top: 120px;
    min-height: 100vh;
    max-height: 1080px;
    padding-bottom: 92px;
    display: flex;
    overflow: visible;

    .cont {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-caption-wr {
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding-bottom: 26px;
    }

    &-caption {
        flex: 1 0 auto;
        max-width: 100%;
    }

    &-title {
        margin-bottom: 15px;
        padding-bottom: ac(31px, 18px);
    }

    &-motto {
        color: var(--gray);
        font-size: ac(30px, 25px);
    }
}

.hero-form-section {
    margin-top: -93px;
    padding-bottom: 32px;
}

.hero-form {
    width: 100%;
    display: flex;
    background: var(--white);
    border-radius: 10px;
    border: 1.5px solid var(--gray);
    padding: 9px 10px;

    .input-wr {
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        padding-left: ac(29px, 0px);
        font-size: ac(16px, 14px);
    }

    input {
        width: 100%;
        letter-spacing: 0.25px;
        padding-right: ac(23px, 0px);

        &::placeholder {
            color: var(--gray);
            opacity: 0.55;
        }
        @media (max-width: 768px){
            padding-right: 0;
            }
    }

    .select-wr {
        position: relative;
        display: flex;
        align-items: center;
        letter-spacing: 0.25px;
        font-size: ac(16px, 14px);
        color: var(--black-pearl);
        padding-right: 1px;

        &:nth-of-type(2) {
            width: 237px;
            flex-shrink: 0;
            max-width: 31%;
            border-left: 1px solid var(--black-pearl);
            border-right: 1px solid var(--black-pearl);
        }

        &:nth-of-type(3) {
            width: 184px;
            max-width: 25%;
            .select2-container {
                width: 100% !important;
            }
        }
    }

    &-title {
        font-size: ac(30px, 25px);
        margin-bottom: ac(15px, 11px);
    }

    &-wr {
        width: 790px;
        max-width: 100%;
        margin: 0 auto -32px;
    }
}


.hero-sector-icons {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: start;
    margin-right: ac(-81px, 0px);
    margin-top: -8px;

     .sector-icon {
        display: block;
        transition: transform 0.3s;
        transform-origin: center;
        border-radius: 50%;
        overflow: hidden;
        width: ac(160px, 68px);

        &:hover {
            transform: scale(1.05);
        }

        &:nth-of-type(2) {
            transition-delay: 150ms;
            margin-top: ac(120px, 67px, 1024, 1440);
        }

        &:nth-of-type(3) {
            transition-delay: 300ms;
            margin-top: -40px;
        }

        &:nth-of-type(4) {
            transition-delay: 450ms;
            margin-top: ac(79px, 30px, 1024, 1440);
        }

        &.active {
            position: relative;
            z-index: 3;
        }
    }

}

.mission-section {
    padding: ac(103px, 50px) 0 ac(123px, 61px);

    &-motto {
        text-align: center;
        max-width: 836px;
        margin: 0 auto ac(84px, 88px);

        h3 {
            font-size: ac(22px, 20px);
            line-height: 1.7272;
            margin-bottom: ac(19px, 9px);
            color: var(--gray);
        }

    }
    .swiper {
        padding-bottom: 2px;
    }
}

.mission-swiper-wr {
    padding-top: ac(60px, 53px);
    padding-bottom: 40px;
    position: relative;
    margin-left: -4px;
    width: calc(100% + 8px);

    .swiper-slide {
        height: auto;
        display: flex;
    }

}

.mission-swiper-bg {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 113.3%;
    min-width: 883px;
}

.mission-card {
    width: 100%;
    max-width: ac(350px, 302px);
    margin: 0 auto;
    color: var(--gray);
    border: 1.5px solid var(--gray);
    border-radius: 25px;
    padding: 53px 35px 66px;
    text-align: center;
    backdrop-filter: blur(6px);
    font-size: 14px;
    transition: all 0.3s;
    background: rgba(255,255,255, 0);

    &-title {
        --pb: 18px;
        --fz: ac(22px, 20px);
        --line-count: 4;
        --line-height: 1.45;
        line-height: var(--line-height);
        overflow-y: auto;
        font-size: var(--fz);
        max-height: calc(var(--fz) * var(--line-height) * var(--line-count) + var(--pb));
        margin-bottom: 11px;
        padding-bottom: var(--pb);
        width: calc(100% + 10px);
        padding-right: 10px;

        &::before {
            left: 50%;
            transform: translate(-50%);
            width: 40px;
        }
    }

    &-text {
        --fz: 14px;
        --line-count: 7;
        --line-height: 1.72;
        line-height: var(--line-height);
        overflow-y: auto;
        font-size: var(--fz);
        max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
        width: calc(100% + 10px);
        padding-right: 10px;
        scrollbar-color: var(--orange) var(--gray);
        scrollbar-width: 5px;

        &::-webkit-scrollbar {
            width: 4px;
            height: 2px;
            background: var(--gray);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--orange);
            border-radius: 3px;
            cursor: pointer;
        }
    }
    &:hover{
        background: rgba(255,255,255, 0.17);
    }
}
.section-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    &::before{
        content: '';
        display:block;
        padding-top: 59.42%;
    }
    img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.stats-section {
    padding: ac(100px, 50px) 0 ac(80px, 35px);

    .title {
        font-weight: 300;
        margin-bottom: ac(27px, 25px);
        text-align: center;
        font-size: ac(30px, 25px);
    }
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 0 auto;
    max-width: 969px;
    padding-left: ac(12px, 0px);
}

.stats-card {
    text-align: center;

    &-counter {
        font-size: 54px;
        font-weight: 100;
    }

    &-text {
        color: var(--gray);
        opacity: 0.55;
    }
}

.latest-job-section {
    padding: ac(45px, 70px) 0 45px;

    .title {
        padding-bottom: 7px;
        margin-bottom: 31px;
    }

    .title-wr {
        .btn {
            margin-top: -7px;

        }
    }
}

.latest-job-swiper-wr {
    position: relative;
    padding-bottom: ac(55px, 44px);
    padding-right: ac(50px, 0px);


    &::before {
        content: '';
        display: block;
        width: 100%;
        height: calc(100% + 2px);
        position: absolute;
        right: calc(100% + 5px);
        top: -1px;
        z-index: 2;
        background: var(--black);
    }

    .swiper-button {
        margin-top: 25px;
        position: static;
    }

}

.swiper-pagination {
    position: absolute;
    width: 125px !important;
    transform: translateX(calc(-50% + ac(54px, -6px))) !important;
    z-index: 0;
}

.swiper-pagination-bullet {
    transform: none !important;
    background: var(--gray);
    --swiper-pagination-bullet-width: 5px;
    --swiper-pagination-bullet-height: 5px;
    --swiper-pagination-bullet-size: 5px;
    transition: all 0.2s !important;
    --swiper-pagination-bullet-horizontal-gap: 5px;

    &.swiper-pagination-bullet-active-main {
        --swiper-pagination-bullet-width: 15px;
        border-radius: 25px;
    }
}

.latest-job-swiper {
    overflow: visible;

    .swiper-slide {
        height: auto;
        width: fit-content;
        max-width: 100%;
    }
}

.sectors-section {
    padding: ac(83px, 38px) 0 50px;

    .title {
        padding-bottom: 10px;
    }
}

.job-card {
    border: 1.5px solid var(--gray);
    border-radius: 25px;
    padding: ac(46px, 30px) ac(60px, 20px);
    color: var(--gray);
    transition: 0.3s;
    width: ac(520px, 375px, 375, 1440);
    max-width: 100%;

    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    &-sector {
        font-size: ac(16px, 14px);
        color: var(--white);
        transition: color 0.3s;
        margin-bottom: 11px;
    }

    &-title {
        font-size: ac(30px, 25px);
        line-height: 1.26;
        margin: 0 0 11px;
        padding-bottom: 20px;

        &::before {
            width: 40px;
        }
    }

    &-params {
        display: flex;
        padding: 20px 0 25px;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 10px;

        li {
            flex-shrink: 0;
            flex-shrink: 0;
            padding: 7px 10px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: var(--black-pearl);
            color: var(--white);
            border-radius: 5px;
            line-height: normal;
            font-size: 14px;
            transition: 0.3s;

            div {
                width: 100%;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    &-description {
        letter-spacing: 0.35px;
        line-height: 1.625;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .btn{
        font-size: 16px;
        color: var(--white);
        margin-top: 19px;
        width: fit-content;
    }


    &:hover {
        background: var(--white);
        color: var(--black-pearl);
        .btn{
            color: var(--black);
        }

        .job-card-sector {
            color: inherit;
        }

        .job-card-params {
            li {
                background: var(--gray);
            }
        }
    }
}

.sectors-list {
    display: flex;
}
.sectors-list.sectors-list-smp{
    .sector-card{
        margin-bottom: 0;
    }
}
.sector-card-wr{
    flex-grow: 1;
    margin-right: 5px;
    --duration: 300ms;
    transition: all;
    transition-duration: var(--duration);
    &:first-of-type {
        .sector-card{
            border-radius: 15px 0 0 15px;
        }
    }

    &:last-of-type {
        margin-right: 0;
        .sector-card{
            border-radius: 0 15px 15px 0;

        }
    }

    &.active{
        flex-grow: 2.7;
        .sector-card-content-hidden {
            pointer-events: all;

            &-title, &-description, .btn {
                opacity: 1;
                transform: translateY(0);
            }

        }

        .sector-card-title {
            opacity: 0;
            pointer-events: none;
        }
    }

}

.sector-card {
    position: relative;
    height: 536px;
    padding: 1px;
    background: linear-gradient(0deg, #97A2AC, #050D14);
    margin-bottom: 80px;
    cursor: pointer;

    &:hover {
        background: linear-gradient(45deg, var(--sector-color), #050D14 65%);

        h3.sector-card-title {
            color: var(--sector-color);
        }
    }

    .sc-hidden-wr {
        max-height: 100%;
        overflow-y: auto;
        padding-left: 10px;
        width: calc(100% + 10px);
        @extend .scroll-bar-style;
        --primary: var(--sector-color);
        width: calc(100% + 20px);
        padding-right: 10px;
        margin-right: -20px;
        &.scrollbar-hidden{
            &::-webkit-scrollbar {
                background: rgba(0, 0, 0, 0);
            }

            &::-webkit-scrollbar-thumb {
                background:  rgba(0, 0, 0, 0);
            }
        }
    }

    &-content {
        width: 100%;
        height: 100%;
        background: var(--black);
        border-radius: inherit;

        h3 {
            font-size: ac(54px, 40px);
        }

        &-hidden-wr {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        &-hidden {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: ac(96px, 40px) ac(90px, 35px);
            display: flex;
            opacity: 0;
            pointer-events: none;

            &-title, &-description, .btn {
                transition: 0.3s 0.5s;
                opacity: 0;
                transform: translateY(-10px);
            }

            &-title {
                margin-bottom: 15px;
                line-height: 1;
            }

            &-description {
                padding-bottom: 15px;
                transition-delay: 0.6s;
            }


            .btn {
                align-self: flex-start;
                transform: translateY(10px);
                opacity: 0;
                transition-delay: 0.75s;
            }
        }
    }

    &-title {
        writing-mode: vertical-rl;
        position: absolute;
        left: 50%;
        transform: translate(-50%) scale(-1, -1);
        bottom: 90px;
        transition: opacity 0.3s, color 0.3s;
        transition-duration: var(--duration);
        line-height: 1.2;
    }

    &-icon {
        position: absolute;
        width: ac(100px, 60px);
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
    }

}

.progress-section {
    padding: ac(92px, 53px) 0;
    overflow: visible;

    .cont {
        transition: padding 0.3s;
    }
}

.progress-swiper-wr {
    position: relative;

    .swiper {
        overflow: visible;
        margin: 0 auto;

        &:not(.swiper-initialized) {

            .swiper-wrapper {
                justify-content: space-between;
            }
        }
    }

    .swiper-slide {
        max-width: fit-content;

        &:last-of-type {
            margin-right: 0 !important;
        }

        &.swiper-slide-active {
            z-index: 2;
        }
    }
    .swiper-pagination{
        position: relative;
        margin: 0 auto;
        transform: none!important;
        left: 0!important;
        padding-top: 40px;
        display: none;
        @media (max-width: 1100px){
             display: block;
          }
    }

}

.progress-swiper-line {
    position: absolute;
    left: 0;
    top: 55.9%;
    transform: translateY(-50%);
    width: 100%;
    height: 3px;
    background: radial-gradient(#97A2AC, var(--black));
}

.progress-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--gray);
    justify-content: space-between;
    min-height: 117px;
    line-height: 1;
    cursor: pointer;
    position: relative;
    min-width: 120px;


    h3 {
        color: var(--white);
        font-size: ac(30px, 28px);
        font-weight: 300;
    }

    &-point {
        width: 28px;
        height: 28px;
        border: 5px solid var(--black);
        background: var(--white);
        border-radius: 50%;
    }

    &-popup-wr {
        position: absolute;
        left: 50%;
        top: 50%;
        width: fit-content;
        transform: translate(-50%, -50%);
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;
    }

    &-text {
        letter-spacing: 0.35px;
    }

    &-popup {
        width: 498px;
        height: 352px;
        max-width: 34.58vw;
        border: 1.5px solid var(--gray);
        border-radius: 25px;
        padding: ac(40px, 20px) ac(50px, 25px);
        text-align: left;
        background: var(--black);
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: scale(0);
        transform-origin: center;
        transition: all 0.3s;
        min-width: 302px;
        position: relative;
        overflow: hidden;
        &-bg{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: 25px;
            overflow: hidden;
            z-index: 0;
            &::before{
                content: '';
                display:block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg,#0d151c,rgba(13,21,28,.65),#0d151c);
                z-index: 1;
            }

            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 0;
                border-radius: 25px;
                filter: grayscale(1);
            }
        }
        &-container {
            max-height: 100%;
            overflow-y: auto;
            scrollbar-color: var(--orange) var(--gray);
            scrollbar-width: 5px;
            padding-right: 10px;
            width: calc(100% + 10px);

            &::-webkit-scrollbar {
                width: 4px;
                height: 2px;
                background: var(--gray);
                border-radius: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--orange);
                border-radius: 3px;
                cursor: pointer;
            }
        }

        .progress-card-year {
            margin-bottom: 15px;
        }

        .progress-card-text {
            margin-bottom: 21px;
            line-height: 1.625;
            position: relative;
            z-index: 1;
        }
    }

    .btn {
        color: var(--white);
        margin-bottom: 0;
    }

}

.active{
    .progress-card-popup {
        transform: scale(1);
        opacity: 1;
    }
}

.progress-swiper-wr {
    .active {
        z-index: 5;

        .progress-card {
            .progress-card-popup-wr {
                pointer-events: all;
                opacity: 1;
            }
        }
    }
}

.latest-news-section {
    padding: ac(73px, 51px) 0;

    .title {
        padding-bottom: 21px;
        margin-bottom: 0;
    }

    .title-wr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: ac(36px, 20px);

        a {
            margin-top: 6px;
        }
    }
}

.news-swiper-wr {
    padding-bottom: ac(54px, 45px);
    position: relative;
    .swiper-slide{
        height: auto;
        display: flex;
    }
}

.news-swiper {
    overflow: visible;
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        right: calc(100% + 2px);
        top: -1px;
        width: 100%;
        height: calc(100% + 2px);
        background: var(--black);
        z-index: 2;

    }

    .swiper-slide {
        max-width: 100%;
        width: fit-content;
    }
}

.news-card {
    width: ac(520px, 375px, 375, 1440);
    max-width: 100%;
    margin: 0 auto;
    border: 1.5px solid var(--gray);
    border-radius: 25px;
    display: flex;
    overflow: hidden;
    justify-content: flex-end;
    transition: background 0.3s;

    &-text {
        padding: ac(48px, 38px, 1024, 1440) ac(59px, 38px, 1024, 1440) ac(65px, 46px, 1024, 1440);
        flex-grow: 1;
        transition: color 0.3s;
    }

    &-thumbnail {
        flex-shrink: 0;
        width: 33.26%;
        position: relative;
        overflow: hidden;
        border-radius: inherit;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;




        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.5s;
            border-radius: inherit;
        }

    }

    &-time {
        font-size: 14px;
        margin-bottom: 16px;

        span {
            color: var(--gray);
            transition: color 0.3s;

            &::before {
                content: '';
                display: inline-block;
                width: 4px;
                height: 4px;
                transform: translateY(-3px);
                background: var(--primary);
                border-radius: 50%;
                margin: 0 9px;
            }
        }
    }

    &-title {
        font-size: 24px;
        position: relative;
        margin-bottom: 28px;
        line-height: 1.41;
        color: var(--gray);
        padding-bottom: 21px;
        transition: color 0.3s;

        &::before {
            content: '';
            display: block;
            background: var(--primary);
            width: 40px;
            height: 4px;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    &-description {
        --line-count: 4;
        --fz: 14px;
        --line-height: 1.71;
        line-height: var(--line-height);
        overflow-y: auto;
        font-size: var(--fz);
        max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
        padding-right: 10px;
        width: calc(100% + 10px);
        transition: color 0.3s;

        &::-webkit-scrollbar {
            width: 4px;
            height: 2px;
            background: transparent;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 3px;
            cursor: pointer;
        }
    }
    .btn{
        margin-top: 25px;
    }
    &:hover{
        background: var(--white);
        color: var(--black-pearl);
        .news-card-title, .news-card-time span{
            color: var(--black-pearl);
        }
        .news-card-thumbnail img{
            transform: scale(1.1);
        }
    }
}

.partners-section {
    padding: ac(65px, 37px) 0;

    .title {
        color: var(--gray);
        font-size: 22px;
        margin-bottom: ac(37px, 28px);
        text-align: center;
    }
}

.partners-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 1px;
}

.partners-card {
    width: 25%;
    min-width: 120px;
    height: ac(160px, 85px, 375, 1440);
    border: 1px solid var(--gray);
    position: relative;
    padding: ac(45px, 26px, 375, 1440) ac(45px, 35px, 375, 1440);
    display: grid;
    place-items: center;
    margin-top: -1px;
    margin-left: -1px;

    &.rounded-tl-xl {
        border-top-left-radius: 15px;
    }

    &.rounded-tr-xl {
        border-top-right-radius: 15px;
    }

    &.rounded-bl-xl {
        border-bottom-left-radius: 15px;
    }

    &.rounded-br-xl {
        border-bottom-right-radius: 15px;
    }


    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - ac(45px, 35px, 375, 1440) * 2);
        height: calc(100% - ac(45px, 26px) * 2);
        object-fit: contain;
    }
}

.journey-section {
    padding: ac(85px, 59px) 0 ac(85px, 35px);

    .title {
        padding-bottom: 9px;
        margin-bottom: ac(29px, 45px);
    }
}

.journey-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 8px 10px;
    margin-bottom: 57px;

    .col-first {
        grid-gap: inherit;

        .journey-list-item {
            &:nth-of-type(2), &:nth-of-type(3) {
                grid-column: span 2;

                &::before {
                    padding-top: 50%;
                }
            }
        }
    }

    .col-last {
        grid-gap: inherit;

        .journey-list-item {
            &:nth-of-type(1) {
                grid-row: span 2;
            }
        }
    }

    &-item {
        position: relative;
        border-radius: ac(25px, 15px);
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.5s;
            border-radius: inherit;
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.journey-text {
    display: flex;
    justify-content: space-between;
    max-width: 836px;
    margin: 0 auto;

    &-title {
        font-size: ac(30px, 22px);
        margin-bottom: 20px;
        color: var(--light-gray);
        max-width: 251px;
        flex-shrink: 0;
        line-height: 1.266;
        margin-right: 20px;
    }

    &-description-wr {
        flex-grow: 1;
        max-width: 480px;
    }

    &-description {
        padding-top: 10px;
        color: var(--gray);
        font-size: 16px;
        margin-bottom: 25px;
        line-height: 1.625;
        letter-spacing: 0.35px;
    }

    .btn {
        font-size: 16px;
        letter-spacing: 0.35px;
    }
}

.dive-section {
    padding: ac(65px, 59px) 0;

    .title {
        padding-bottom: ac(6px, 22px);
        margin-bottom: 15px;
        font-size: ac(80px, 36px, 320, 1440);
    }

    a {
        font-size: ac(30px, 25px);
        margin: 0 auto;
        font-weight: 300;
        display: block;
        width: fit-content;
        transition: color 0.3s;

        &:hover {
            color: var(--orange);
        }
    }
}
.dive-section.dive-section-sectors{
    .title{
        font-size: ac(70px, 36px);
        text-wrap: balance;
    }
}

.hidden{
    display: none;
}
.nf-jobs-result{
    padding-top: 100px;
}

.popup__block:not(.popup__block__drop_cv){
    max-height: calc(var(--vh, 1vh) * 100 - 40px);
    border-radius: 25px;
    background: var(--black-2);
    border: 1px solid var(--white);
    padding: 50px 20px 20px;
    .popup__btn-close .cross-clip{
        background: var(--orange);
    }
    .popup__title{
        font-size: ac(30px, 24px);
        color: var(--white);
        margin-bottom: 25px;
    }
    form{
        background: var(--white);
        padding: ac(50px, 20px);
        border-radius: 10px;
    }
    h3{
        color: var(--black);
    }
    input, textarea{
        width: 100%;
        padding-bottom: 17px;
        margin-bottom: 17px;
        border-bottom: 1px solid var(--black-pearl)!important;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 50000s ease-in-out 0s;
            -webkit-text-fill-color: inherit !important;
        }
    }
    .buttons-wrap{
        display: flex;
        justify-content: flex-end;
        padding-top: 15px;
    }
}

.popup__block.popup__block__drop_cv{
    padding: 20px;
    padding: 20px;
    background: none;
    width: 1096px;
    max-width: 100%;
    overflow: visible;
    .popup__btn-close .cross-clip{
        background: var(--orange);
        z-index: 1;
    }
    .drop-form-wr{
        position: relative;
        background: var(--black-2);
    }
}

@media (max-width: 1200px) {

}

@media (max-width: 1100px) {
    .progress-swiper-wr {
        .swiper-slide {
            @media (min-width: 651px) {
                max-width: revert;
            }
        }
    }

}

@media (max-width: 992px) {
    .news-card {
        flex-direction: column-reverse;

        &-thumbnail {
            width: 100%;
            border-radius: inherit;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            height: 126px;


            &::before {
                padding-top: 41.72%;
            }
        }
    }
}

@media (max-width: 768px) {
    .hero-section-form .select-wr {
        &:nth-of-type(2) {
            max-width: 31%;
        }

        &:nth-of-type(3) {
            max-width: 21%;
        }
    }

    .mission-swiper-wr {
        .swiper {
            overflow: visible;
        }
    }

    .stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .journey-text {
        flex-direction: column;

        &-description-wr {
            max-width: unset;
        }
    }


}

@media (max-width: 650px) {
    .progress-swiper-line {
        width: 1010px;
    }
}

.home-page .hero-section-bg{
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(0deg,#0d151c,rgba(13,21,28,0) 50%);
    }
}



@media (max-width: 768px) {
    .hero-form {
        flex-direction: column;
        position: relative;
        padding: 26px 30px;

        .input-wr, .select-wr {
            border: none !important;
            border-bottom: 1px solid var(--black-pearl) !important;
            width: 100% !important;
            max-width: unset !important;
            padding-bottom: 17px;
            margin-bottom: 14px;
        }

        .select-wr {
            padding-left: 0 !important;

            &:nth-of-type(2) {
                padding-bottom: 14px;
                margin-bottom: 14px;

            }

            &:nth-of-type(3) {
                margin-bottom: 0;
                padding-bottom: 8px;
                width: calc(100% - 65px) !important;
            }
        }

        .submit-btn {
            position: absolute;
            right: 29px;
            bottom: 26px;
        }

        .select2-container--default .select2-selection--single .select2-selection__arrow {
            right: -7px;
        }
    }
}

/*--mobile-menu-start-point 640*/
@media (--mobile-menu-start-point) {

        .sector-hero-section{
            padding-top: 264px!important;
            .hero-sector-icons {
                top: 131px;
                right: 34px;
                .sector-icon{
                    width: 108px;
                }
            }
        }

    .team-swiper-wr .swiper:before{
        display: none!important;
    }

    .cont {
        width: 100%;
        margin: 0 auto;
        padding: 0 34px;
    }
    .hero-section-bg:before{
        background: radial-gradient(rgba(13,21,28,0.31),#050d14);
    }

    .news-swiper:before {
        display: none;
    }

    .home-page .hero-section-bg {
        width: 101%;
        img {
            --imgHeight: 25px;
            height: calc(100% + var(--imgHeight));
            object-position: 46% calc(-1 * var(--imgHeight));
        }
    }
    .news-page .hero-section .hero-section-caption{
        margin-bottom: 0;
    }


    .partners-list {
        max-width: 450px;
        margin: 0 auto;
    }

    .partners-card {
        width: 50%;
    }

    .latest-job-swiper-wr:before {
        display: none;
    }



    .hero-section-caption-wr {
        flex-direction: column;
        align-items: flex-start;
    }
    .about-us-page{
        .hero-section-caption-wr{
            justify-content: center;
            padding-bottom: 160px;
        }

    }

    .hero-section {
        padding-top: 152px;
        padding-bottom: 210px;

        &-title {
            line-height: 1.1;
        }

        &-caption {
            flex: 0 0 auto;
            max-width: 100%;
            margin: 0 auto 37px;
        }
    }

    .hero-sector-icons {
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 10px;
        width: fit-content;
        margin: 0 auto;

    .sector-icon{
            margin-top: 0 !important;

            &:nth-of-type(even) {
                margin-top: 33px !important;
            }
        }
    }

    .scroll-down-label {
        transform: translate(-50%);
        bottom: 140px;
    }



    .mission-section {
        padding-top: 48px;

        &-motto {
            line-height: 1.8;

            h3 {
                line-height: inherit;
            }

        }
    }

    .sectors-list {
        flex-direction: column;
    }

    .sector-card-wr{
        margin-right: 0;
        &:nth-of-type(1) {
            .sector-card{
                border-radius: 15px 15px 0 0;
            }
        }

        &:nth-of-type(4) {
            .sector-card{
                border-radius: 0 0 15px 15px;
                margin-bottom: 0;
            }

        }
        &.active {
            .sector-card-title {
                bottom: calc(100% - 70px);
            }

            .sector-card-content-hidden {
                max-height: 600px;
            }
        }
    }

    .sector-card {
        height: auto;
        margin-left: auto;
        background: linear-gradient(90deg, #97a2ac, #050d14);
        margin-bottom: 3px;
        &-content {
            min-height: 163px;

            &-hidden {
                position: relative;
                padding-left: 50px;
                transition: all 0.3s ease-out, max-height 1s ease-out !important;
                max-height: 0;
            }
        }

        &-icon {
            bottom: 50%;
            left: 0 !important;
            transform: translate(-50%, 50%);
        }

        &-title {
            left: 53px !important;
            transform: translate(0, 45%);
            writing-mode: revert;
            bottom: 50%;
            transition: 0.5s;
            transition-duration: var(--duration);
        }

    }

    #progress-card-mobile-popup {
        position: static;
        margin: 25px auto 0;
        transform: none;
        width: 300px;
        max-width: 95vw;
        opacity: 1;
        pointer-events: all;
        transition: none;
        .progress-card-popup{
            min-width: 0;
            width: 100%;
            max-width: 100%;
        }
    }

    .progress-swiper-wr {
        margin-left: -2px;

        .swiper-wrapper {
            position: static;

        }

        .swiper-slide {
            position: static;
            transform: none;

            .progress-card {
                position: static;
                min-height: 111px;
                padding-right: 50px;
            }
        }
    }

    .mission-swiper-wr {
        margin-left: 0;
        width: 100%;
    }

    .mission-card {
        padding: 37px 25px 35px;

        &-title {
            line-height: 1.5;
            padding-bottom: 15px;
        }
    }

    .stats-section {
        .title {
            line-height: 1.32;
            margin-bottom: 19px;
        }
    }

    .latest-job-section .title-wr {
        margin-bottom: 19px;
        .title{
            margin-bottom: 0;
            padding-bottom: 16px;
        }

        .btn {
            align-self: flex-end;
            margin-top: 0;
            margin-bottom: 0px;
        }
    }

    .job-card {
        &-sector {
            margin-bottom: 20px;
        }

        &-title {
            padding-bottom: 27px;
            margin-bottom: 10px;
        }

        &-params {
            padding-bottom: 26px;
        }

        &-description {
            font-size: 14px;
            line-height: 1.714;
        }
    }

    .sectors-section {
        .title {
            margin-bottom: 67px;
        }
    }

    .journey-list {
        grid-gap: 3px 4px;
        margin-bottom: 57px;
    }

    .journey-text {
        &-title {
            line-height: 1.72;
            max-width: 65%;
            margin-bottom: -1px;
        }
    }

    .dive-section {
        .title {
            line-height: 1;
        }
    }

    .latest-news-section .title-wr {
        margin-bottom: 19px;
        .title {
            margin-bottom: 0;
            padding-bottom: 16px;
        }

        .btn {
            align-self: flex-end;
            margin-bottom: 0;
        }
    }
    .swiper-pagination{
        width: 125px!important;
    }

    .footer {
        padding-bottom: 80px !important;
    }


}

@media (max-width: 450px) {
    .hero-section-caption {
        width: 100%;
    }

    .hero-sector-icons {
        width: 100%;
    }
    #progress-card-mobile-popup {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 370px) {
    .progress-swiper-wr .swiper-slide .progress-card{
        padding-right: 30px;
    }

    h1{
        font-size: 44px;
    }
    .video-section h3{
        font-size: 20px;
        padding-bottom: 15px;

    }
    .cont {
        padding: 0 20px;
    }

    .hero-sector-icons .sector-icon {
        width: 62px;
    }
    .job-card{
        padding: 20px;
    }
    .diversity-wr{
        padding: 20px!important;
    }
    .news-card{
        &-title{
            margin-bottom: 20px;
        }
        &-text{
            padding: 30px 20px 25px;
        }
    }
}

@media (max-height: 770px) {
    .hero-section {
        padding-top: 120px;
    }
}
