.pagination {
    margin-top: ac(96px, 39px);
    display: flex;
    align-items: center;
    justify-content: center;

    &__arrow {
        position: relative;
        display: flex;
        justify-content: center;
        width: 28px;
        height: 28px;
        margin-left: 17px;
        border-radius: 5px;
        border: 1.5px solid var(--light-gray);
        transition: .3s ease;
        cursor: pointer;

        &:hover {
            background-color: var(--orange);
        }

        &::before {
            content: '';
            width: 12px;
            height: 26px;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' fill='none'%3E%3Cpath fill='%2397A2AC' d='M6.377 5 1.685 9.692l-.707-.707L4.963 5 .978 1.016l.707-.707L6.377 5Z'/%3E%3Cpath fill='%2397A2AC' d='M11.377 5 6.685 9.692l-.707-.707L9.963 5 5.978 1.016l.707-.707L11.377 5Z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
        }

        &--prev {
            margin-left: 0;
            margin-right: 17px;

            &::before {
                transform: scale(-1);
            }
        }

        &.disabled {
            opacity: .5;
            pointer-events: none;
        }
    }

    &__num {
        display: flex;
        justify-content: center;
        line-height: 39px;
        width: 40px;
        height: 40px;
        margin: 0 3px;
        font-size: 14px;
        border: 1.5px solid var(--light-gray);
        border-radius: 5px;
        cursor: pointer;
        transition: .3s ease;

        &.active, &:hover {
            background-color: var(--orange);
        }
    }
}