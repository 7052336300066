.btn-contact {
    color: var(--black-pearl);
    font-size: 14px;
    line-height: normal;
    background: var(--white);
    padding: ac(15px, 10px);
    min-width:ac( 148px, 125px);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    transition: 0.3s;
    cursor: pointer;
    &:hover{
        background: var(--orange);
        color: var(--white);
    }
    &.transparent-bg{
        background: transparent;
        border: 1.5px solid #ffffff;
        color: #ffffff;
        &:hover{
            background: var(--orange);
        }
    }
}

.submit-btn {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background: var(--blue);
    cursor: pointer;
    transition: 0.3s;
    border-radius: 8px;
    color: var(--white);
    flex-shrink: 0;

    &:hover {
        background: var(--green);
    }
    &.submit-btn-text{
        width: fit-content;
        padding: 0 10px;
        span{
            padding-right: 8px;
        }
    }
}

.btn {
     display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    --hover-btn-color: var(--orange);
    overflow: hidden;
    line-height: normal;
    flex-shrink: 0;

    span {
        font-size: inherit;
        line-height: inherit;
        transition: clip-path 0.3s;
        &::after {
            content: '\e90a';
            font-family: 'icomoon';
            font-size: 5px;
            padding-left: 10px;
        }
        &:first-of-type{
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

        &:last-of-type{
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            color: var(--hover-btn-color);
            clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
            overflow: hidden;
        }
    }
    &.active-icon{
        span{
            &::after{
                color: var(--orange);
            }
        }
    }

    &:hover{
        span{
            &:first-of-type{
                clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);
            }
            &:last-of-type{
                right:auto;
                left: 0;
                width: 100%;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }
    }
}

.swiper-buttons{
    position: relative;
    width: 130px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

}
.swiper-button{
    color: var(--orange);
    margin: 0;
    &.swiper-button-next, &.swiper-button-prev{}
}