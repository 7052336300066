.hero-section-contact {
    padding-top: 227px;

    .hero-section-caption-wr {
        padding-bottom: 23px;
    }
}

.hero-sector-icons-contact {
    margin: 0;
    position: absolute;
    right: 42px;
    top: 185px;
    z-index: 3;

    .sector-icon {
        position: relative;
        overflow: visible;
        width: ac(160px, 120px, 641, 1440);

        .hover-text {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -20px;
            width: 124.5%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            z-index: 2;
            text-align: center;
            font-size: ac(14px, 10px);
            transition: 0.3s;
            opacity: 0;
            a{
                @mixin max-line-length-one;
                transition: 0.3s;
                &:hover{
                    text-decoration: underline;
                }
            }

        }
        &::before{
            content: '';
            display:block;
            position: absolute;
            background: inherit;
            width: 100%;
            height: 100%;
            transform-origin: center top;
            left: 0;
            top: 0;
            z-index: 0;
            border-radius: inherit;
            transition: transform 0.3s;
            transition-delay: 0.15s;
        }
        img{
            position: relative;
            z-index: 1;
        }

        &:hover{


            .hover-text{
                transition-delay: 0.15s;
                opacity: 1;
            }
            &::before{
                transition-delay: 0ms;
                transform: scale(1.425);
            }
        }
        &:nth-of-type(1){
            z-index: 4;
        }
        &:nth-of-type(2){
            z-index: 3;
        }
        &:nth-of-type(3){
            z-index: 2;
        }
        &:nth-of-type(4){
            z-index: 1;
        }
    }

}

.contact-map {
    max-width: 100%;
    width: 1260px;
    position: relative;

    img {
        width: 100%;
    }

    &-wr {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-left: 57px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        min-width: 841px;

    }

    &.active {
        .map-pin {
            opacity: 0.5;
        }

        .map-pin-item.active {
            .map-pin {
                opacity: 1;
            }
        }
    }
}

.map-pin-items {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
}

.map-pin-item {
    position: absolute;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;


    .map-pin {
        width: ac(10px, 8px);
        height: ac(10px, 8px);
        border-radius: 50%;
        background: var(--white);
        position: absolute;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            transform: scale(1.3);
        }
    }

    &:nth-of-type(1) {
        left: 24.75%;
        top: 39%;
    }

    &:nth-of-type(2) {
        left: 47.05%;
        top: 40.3%;
    }

    &:nth-of-type(3) {
        left: 22.2%;
        top: 43.3%;
    }

    &:nth-of-type(4) {
        left: 29.2%;
        top: 39.3%;
    }

    &:nth-of-type(5) {
        left: 47.3%;
        top: 32.3%;
    }

    &:nth-of-type(6) {
        left: 46.7%;
        top: 30.6%;
    }

    &:nth-of-type(7) {
        left: 48.7%;
        top: 30.8%;
    }

    &:nth-of-type(8) {
        left: 48.5%;
        top: 32%;
    }

    &:nth-of-type(9) {
        left: 56.8%;
        top: 31.6%;
    }

    &.active {
        display: flex;
        z-index: 2;

        .map-popup {
            transform: scale(1);
            z-index: 2;
        }
    }
}

.map-popup {
    position: absolute;
    left: 130%;
    top: 6px;
    width: 268px;
    border-radius: 25px;
    border: 1.5px solid var(--light-gray);
    padding: 49px 37px;
    display: flex;
    z-index: 1;
    backdrop-filter: blur(10px);
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--gray);

    /*animation: poupfadeIn 0.3s forwards;*/
    transform: scale(0);
    transition: transform 0.3s;
    transform-origin: left top;

    h3 {
        font-size: ac(22px, 20px);
        padding-bottom: 20px;
        margin-bottom: 15px;
        color: var(--gray);
    }

    .tel-link {
        color: var(--white);
    }

    a {
        transition: color 0.3s;
    }

    a:hover {
        color: var(--orange);
    }
}

.contact-text-section {
    padding: 21px 0 52px;
    color: var(--gray);
    font-size: 14px;

    .title {
        font-size: ac(30px, 26px);
        padding-bottom: 9px;
        margin-bottom: 20px;
    }

    p {
        line-height: 171%;
        max-width: 694px;
    }
}

@media (max-width: 768px) {
    .contact-map-wr {
        transform: translateX(-46.2%);
    }
}

@media (max-width: 640px) {
    .hero-section-contact {
        padding-top: 210px;

        .hero-section-title {
            line-height: 1.3;
        }

    }

    .contact-page {
        .hero-section-caption {
            margin: 0;
        }

    }

    .hero-sector-icons-contact {
        right: 13px;
        grid-template-columns: 50% 50%;
        width: fit-content;
        grid-column-gap: 0;
        top: 153px;
        .sector-icon{
            width: 80px;
            &::before{
                display: none;
            }
            .hover-text{
                display: none;
            }
            &:nth-of-type(1){
                left: 20px;
            }
            &:nth-of-type(2){
                margin-top: 92px!important;
            }
            &:nth-of-type(3){
                margin-top: -28px!important;
                left: -14px;
            }
            &:nth-of-type(4){
                margin-top: 55px!important;
            }
        }
    }

    .contact-map-wr {
        margin-top: 40px;
    }
}

@media (max-width: 370px) {
    .contact-map-wr {
        min-width: 720px;
        transform: translateX(-47%);
    }
}
