.energy-page {

    @media (min-width: 1200px) {
        .partners-section {
            padding-bottom: 87px;
        }
    }
}

.rail-page {
    @media (min-width: 1200px) {
        .case-study-section {
            padding-top: 29px;
        }

        .partners-section {
            padding-bottom: 87px;
        }

        .latest-news-section {
            padding-bottom: 60px;

            .title {
                padding-bottom: 9px;
            }

            .title-wr a {
                margin-top: 23px;
            }
        }
    }
}

.projects-page {
    @media (min-width: 1200px) {
        .stats-section {
            padding: 121px 0 82px;
        }

        .work-with-section {
            padding-bottom: 50px;
        }

        .case-study-section {
            padding-top: 70px;
        }

        .latest-job-section {
            padding-top: 65px;
        }

        .latest-news-section {
            padding-bottom: 60px;

            .title {
                padding-bottom: 9px;
            }

            .title-wr a {
                margin-top: 23px;
            }

        }
    }
}

.tech-page {
    @media (min-width: 1200px) {
        .sector-mission-cards {
            padding: 95px 0 126px;

            &-bg {
                transform: translateY(-6%);
            }

            .mission-card {
                padding-bottom: 44px;
            }
        }

        .stats-section {
            padding-top: 113px;
        }

        .work-with-section {
            padding-bottom: 50px;
        }

        .case-study-section {
            padding-top: 74px;
        }

        .latest-job-section {
            padding-top: 65px;
            & + .latest-news-section{
                padding-top: 0;
            }
        }

        .latest-news-section {
            padding-bottom: 60px;

            .title {
                padding-bottom: 9px;
            }

            .title-wr a {
                margin-top: 23px;
            }


        }
    }
    .latest-job-section {

        & + .latest-news-section{
            padding-top: 0;
        }
    }
}


.video-section {
    color: var(--light-gray);
    margin-top: -86px;
    padding: 55px 0;

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &-text {
        max-width: 410px;
        margin-right: 25px;
        padding-bottom: 15px;
        line-height: 155%;
    }

    .video-wr {
        max-width: 50%;
    }

    h3 {
        margin-bottom: 11px;
        font-weight: 200;
        color: var(--white);
        padding-bottom: 23px;
    }

    p {
        font-size: 14px;
    }
}

.video-wr {
    width: 552px;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    border: 1.5px solid var(--light-gray);
    background: var(--light-gray);
    flex-shrink: 0;
    --plyr-video-control-background-hover: var(--primary);
    --plyr-range-fill-background: var(--primary);
    --plyr-color-main: var(--primary);

    &::before {
        content: '';
        display: block;
        padding-top: 56.04%;
    }

    video {
        border-radius: 25px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

    }

    .plyr {
        position: absolute;
        inset: 0;
        border-radius: inherit;
        &:not(.start-playing){

                .plyr__controls{
                    opacity: 0;
                    pointer-events: none;
                }

        }

    }
    .plyr__video-wrapper{
        background: var(--light-gray);
    }

    .plyr__poster {
        -webkit-background-size: cover;
        background-size: cover;
        background-color: var(--light-gray);
    }

    .plyr__control--overlaid {
        height: ac(105px, 71px);
        width: ac(105px, 71px);
        left: 48.8%;
        svg {
            width: ac(69px, 41px);
            height: ac(70px, 33px);
            left: 9%;
        }
    }


}

.sector-mission-cards {
    position: relative;
    padding: ac(173px, 60px) 0 ac(126px, 78px);

    .mission-card {
        padding-bottom: 50px;
    }

    .mission-card-title:before {
        transform: translate(-60%);
    }

    .swiper {
        padding-bottom: 2px;
    }
}

.sector-mission-cards-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateY(5%);

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: -1px;
        width: 100%;
        height: calc(100% + 2px);
        background: linear-gradient(to top, rgba(13, 21, 28, 1), rgba(13, 21, 28, 0.85), rgba(13, 21, 28, 1));
        z-index: 1;
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }
}

.sector-mission-swiper-wr {
    .swiper-slide {
        height: auto;
        display: flex;
    }
}

.jt-section {
    padding: 24px 0;

    &-bg {
        height: auto;
        overflow: visible;
        z-index: 0;
        opacity: 0.06;
        width: 132.99%;
        left: 50%;
        transform: translate(-49.7%, -20.3%);

        &::before {
            content: '';
            display: block;
            padding-top: 26.87%;
        }

    }

    &-content {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 1;
    }

    &-thumbnail {
        border-radius: 25px;
        position: relative;
        overflow: hidden;
        width: 372px;
        max-width: 40%;
        flex-shrink: 0;
        align-self: flex-start;

        &::before {
            content: '';
            display: block;
            padding-top: 71.23%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-tabs {
        flex-grow: 1;
        padding: ac(28px, 20px) ac(33px, 20px) ac(40px, 20px) ac(55px, 30px);
        font-size: 14px;

        &-list {
            display: flex;
            margin-bottom: 17px;
            gap: ac(42px, 20px);
        }

        &-item-content {
            max-width: 552px;
            line-height: 171%;
        }
    }

    &-tab {
        h3 {
            cursor: pointer;
            transition: color 0.3s;
            padding-bottom: 14px;
            font-size: ac(30px, 26px, 1024, 1440);
            line-height: 1.2;

            &::before {
                display: none;
            }

            &.active {
                &::before {
                    display: block;
                }
            }

            &:hover {
                color: var(--orange);
            }
        }
    }
}

.sdv-section {
    padding: ac(114px, 50px) 0 49px;

    .title {
        margin-bottom: 52px;
        padding-bottom: 10px;
    }
}

.sdv-swiper {
    overflow: visible;
    max-width: 1040px;
    margin: 0 auto;
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(to right, rgba(13, 21, 28, 1), rgba(28, 108, 137, 1), rgba(13, 21, 28, 1));
    }
}

.sdv-body {
    position: relative;
    display: flex;
    column-gap: 19px;
    justify-content: space-between;
}

.sdv-item {
    --tr-duration: 500ms;
    position: relative;
    padding-bottom: 28px;
    width: ac(36px, 32px);
    transition: all 0.5s;
    transition-duration: 380ms;
    min-height: 254px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;

    &::before {
        content: '';
        display: block;
        width: 28px;
        height: 28px;
        border: 5px solid var(--black);
        border-radius: 50%;
        background: var(--white);
        position: absolute;
        left: 4px;
        bottom: 0;
        transform: translateY(50%);
        transition-duration: inherit;
    }

    &-thumbnail {
        position: absolute;
        left: 0;
        bottom: 4px;
        overflow: hidden;
        width: 100%;
        max-width: 381px;
        z-index: -1;
        opacity: 0;
        transition: all;
        transition-duration: inherit;
        transform: scale(0);
        transform-origin: center;

        &::before {
            content: '';
            display: block;
            padding-top: 66.66%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .mixed-layer {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            &:nth-of-type(1) {
                z-index: 2;
                background: linear-gradient(to right, rgba(13, 21, 28, 1), rgba(13, 21, 28, 0.65), rgba(13, 21, 28, 1));
            }

            &:nth-of-type(2) {
                z-index: 1;
                background: linear-gradient(to top, rgba(13, 21, 28, 1), rgba(13, 21, 28, 0.65), rgba(13, 21, 28, 1));
            }
        }
    }

    &-title {
        font-weight: 300;
        margin-bottom: 0;
        transform-origin: 17px center;
        transform: rotate(-90deg);
        width: max-content;
        transition: all 0.3s;
        transition-duration: inherit;
        font-size: ac(30px, 26px);
    }

    &-content {
        display: none;
        padding-bottom: 22px;
        font-size: 14px;

        &-text {
            max-width: 500px;
            margin-bottom: 20px;
        }
    }

    &:hover {
        &::before {
            background: var(--orange);
        }

        .sdv-item-title {
            color: var(--orange);
        }
    }

    &.active {
        flex-grow: 1;

        &::before {
            background: var(--blue);
        }

        .sdv-item-title {
            transform: none;
            color: var(--orange);
            margin-bottom: 20px;
        }

        .sdv-item-thumbnail {
            opacity: 1;
            transform: none;
        }
    }
}

#sdv-item-content-mobile {
    margin-top: 20px;

    .sdv-item-title {
        transform: none;
        color: var(--orange);
        margin-bottom: 13px;
    }
}

.stats-section.stats-section-col-3 {
    .stats-grid {
        display: flex;
        gap: ac(114px, 20px);
        justify-content: center;
        flex-wrap: wrap;
    }

    .stats-card {
        flex-wrap: wrap;
        min-width: 136px;
    }
}

.team-swiper-section {
    padding: 45px 0;

    .title {
        width: calc(100% + 7px);
        margin-bottom: 35px;
        padding-bottom: 9px;
    }
}

.team-swiper-wr {
    padding-bottom: 54px;
    position: relative;

    .swiper {
        overflow: visible;
        position: relative;

        &::before {
            content: '';
            display: block;
            z-index: 2;
            background: var(--black);
            position: absolute;
            width: 100%;
            height: calc(100% + 2px);
            right: calc(100% + 2px);
            top: -1px;
        }
    }

    .swiper-slide {
        width: fit-content;
        max-width: 100%;
        height: auto;
        display: flex;
    }

    .swiper-pagination {
        @media (min-width: 1200px) {
            transform: translateX(-9px) !important;
        }
    }
}

.teammate-card {
    max-width: 100%;
    width: 410px;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    overflow: hidden;
    border: 1.5px solid var(--light-gray);
    transition: all 0.3s;

    &-thumbnail {
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            padding-top: 56.56%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s;
        }
    }

    &-text {
        color: var(--gray);
        padding: ac(36px, 20px) ac(60px, 24px) ac(30px, 20px);
        background: var(--black);
        font-size: 14px;
        flex-grow: 1;

        h3 {
            font-size: ac(24px, 20px);
            font-weight: normal;
            margin-bottom: 10px;
        }


        a {
            line-height: normal;
            transition: color 0.3s;

            &:hover {
                color: var(--orange);
            }
        }

        i {
            font-size: 23px;
        }
    }

    &-info {
        display: flex;
        gap: 15px;
        justify-content: space-between;
        align-items: flex-start;
        p {
            color: var(--white);
        }
    }

    &-info-descr {
        margin-top: 25px;
        line-height: 171%;
    }

}

.work-with-section {
    padding: ac(92px, 50px) 0;

    .ww-swiper-wr {
        margin: 0 0 0 12.5vw;
        padding: 0;
        @media (max-width: 640px) {
            margin: 0;
            padding: 0;
            .swiper{
                padding-left: 20px;
                padding-right: 20px;
            }
        }


    }

    .title {
        color: var(--gray);
        font-size: 22px;
        margin-bottom: 57px;
        text-align: center;
    }

    .swiper {
        overflow: visible;
        position: relative;

        &::before {
            content: '';
            display: block;
            z-index: 2;
            background: var(--black);
            position: absolute;
            width: 100%;
            height: calc(100% + 2px);
            right: calc(100% + 2px);
            top: -1px;
        }
        @media (min-width: 451px) {
            width: 100%;
        }

        @media (max-width: 640px) {
            &::before {
                display: none;
            }
        }
    }

    .swiper-slide {
        --padding-right: ac(97.8px, 50px);


        max-height: 68px;
        display: flex;
        align-items: center;
        padding-right: var(--padding-right);
        height: auto;
        img{
            max-height: 100%;
        }
        @media (max-width: 450px) {
            --padding-right:0;
            max-width: 100%;
            padding-left: 19px;
            padding-right: 19px;
            justify-content: center;
        }

    }

    .swiper-button {
        position: static;
        margin-top: 35px;
    }
}

.case-study-section {
    padding: ac(100px, 50px) 0 ac(73px, 35px);

    &-content {
        max-width: 836px;
        margin: 0 auto;
    }

    .title {
        padding-bottom: ac(8px, 16px);
        margin-bottom: 0;
    }

    .title-wr {
        margin-bottom: ac(23px, 20px);
        align-items: flex-end;
    }

}

.case-study-card {
    display: flex;
    overflow: hidden;
    border-radius: 25px;
    border: 1.5px solid var(--light-gray);
    justify-content: space-between;

    &-thumbnail {
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        width: 49%;

        &::before {
            content: '';
            display: block;
            padding-top: 88.34%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-text {
        padding: ac(46px, 26px) ac(60px, 40px) ac(55px, 30px);
        font-size: 14px;
        line-height: 171.4%;

        h3 {
            font-size: ac(24px, 20px);
            margin-bottom: 30px;
            padding-bottom: 24px;
            line-height: 1.41;
        }
        &-content{
            --line-count:7;
            --fz: 14px;
            --line-height: 1.714;
            line-height: var(--line-height);
            font-size: var(--fz);
            max-height: calc(var(--fz) * var(--line-height) * var(--line-count));

            .simplebar-scrollbar::before {
                background-color: var(--orange);
                color: var(--orange);
                opacity: 1!important;
                left: 1px;
                right: 1px;
            }

            .simplebar-track.simplebar-vertical{
                background: rgba(255,255,255, 0.2)!important;
                opacity: 1!important;
                width: 5px;
                border-radius: 5px;
                right: -8px;
            }
        }
    }

    &-sector {
        font-size: 14px;
        margin-bottom: 20px;
    }

}

.drop-cv-section {
    padding: ac(84px, 35px) 0 ac(57px, 20px);

    .title {
        padding-bottom: ac(10px, 18px);
        margin-bottom: ac(16px, 22px);
        font-size: ac(54px, 26px);
    }
}

@media (--sdv-mobile-breakpoint) {
    .sdv-body {
        gap: 0;
    }

    .sdv-item {
        user-select: none;

        &.active {
            flex-grow: 0;
            pointer-events: none;

            .sdv-item-title {
                transform: rotate(-90deg) translateX(20px);
                opacity: 0;

            }

            &::before {
                pointer-events: all;
            }
        }

        &-thumbnail {
            display: none;
        }
    }
}

@media (max-width: 1024px) {
    .jt-section {
        &-content {
            flex-direction: column;
        }

        &-thumbnail {
            max-width: 100%;
            margin: 0 auto 30px;
            &.mb-hidden{
                display: none!important;
            }
            &.lg-hidden{
                 display: block!important;
            }
        }

        &-tabs {
            padding: 0;
            &-list{
              justify-content: center;
            }

            &-item-content {
                max-width: 100%;
            }
        }
    }
}


.sdv-vertical-section {
    padding: ac(119px, 50px) 0 33px;
}

.sdv-vertical-body {
    display: flex;
    gap: ac(135px, 35px);
    padding-left: ac(141px, 0px);

    .title {
        padding-top: ac(34px, 0px);
        flex-shrink: 0;
        align-self: flex-start;
        padding-bottom: 9px;
    }
}

.sdv-vertical-body-items {
    flex-shrink: 0;
    flex-grow: 1;
    max-width: 55%;
    position: relative;
    padding: 39px 0;
    transition: 0.3s;

    &:has(.active) {
        padding-bottom: 15px;
    }

    &::before {
        content: '';
        display: block;
        width: 3px;
        position: absolute;
        z-index: 0;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(0deg, #0d151c, #1c6c89, #0d151c);
    }
}

.sdv-vertical-item {
    --padding-left: 33px;
    margin-bottom: ac(63px, 35px);

    &:last-of-type {
        margin-bottom: 0;
    }

    &-content-wr {
        margin-bottom: -33px;
        display: none;
        padding-left: var(--padding-left);
    }

    &-thumbnail {
        position: absolute;
        left: -10px;
        top: 0;
        overflow: hidden;
        width: 100%;
        max-width: 545px;
        z-index: -1;
        opacity: 0;
        transition: all 0.3s;
        transform: scale(0);
        transform-origin: center;
        display: none;

        &::before {
            content: '';
            display: block;
            padding-top: 60.55%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .mixed-layer {
            position: absolute;
            left: -1px;
            top: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);

            &:nth-of-type(1) {
                z-index: 2;
                background: linear-gradient(to right, rgba(13, 21, 28, 1), rgba(13, 21, 28, 0.65), rgba(13, 21, 28, 1));
            }

            &:nth-of-type(2) {
                z-index: 1;
                background: linear-gradient(to top, rgba(13, 21, 28, 1), rgba(13, 21, 28, 0.65), rgba(13, 21, 28, 1));
            }
        }
    }

    &-title {
        transition: 0.3s;
        cursor: pointer;
        width: fit-content;
        padding-left: var(--padding-left);
        position: relative;

        &::before {
            background: var(--white);
            border: 5px solid var(--black);
            border-radius: 50%;
            top: ac(10px, 5px);
            left: 2px;
            content: "";
            display: block;
            height: 28px;
            position: absolute;
            transform: translateX(-50%);
            transition: 0.3s;
            width: 28px;
        }

        &:hover {
            color: var(--orange);
            opacity: 0.8;

            &::before {
                background: var(--orange);
            }
        }
    }

    &-text {
        padding-top: 20px;
        color: var(--gray);
        padding-bottom: 20px;
        font-size: 14px;
    }


    &.active {

        .sdv-vertical-item {

            &-title {
                color: var(--orange);

                &::before {
                    background: var(--orange);
                }
            }
        }

        .sdv-vertical-item-thumbnail {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.sector-gallery-section {
    padding: 64px 0 72px;

    .title {
        margin-bottom: ac(56px, 35px);
    }
}

.gallery-swiper-wr {
    position: relative;
    padding-bottom: 55px;

    .swiper {
        overflow: visible;
    }

    .swiper-slide {
        width: 100%;
        max-width: 410px;
    }
}

.sw-gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 25px;

    &::before {
        content: '';
        display: block;
        padding-top: 60.97%;
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.sm-swiper-wr {
    max-width: 836px;
    margin: 0 auto;

    .swiper-slide {
        height: auto;
        display: flex;
    }

    .mission-card {
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .video-section {
        &-content {
            flex-direction: column;
            align-items: center;
        }

        &-text {
            padding-bottom: 30px;
        }

        .video-wr {
            width: 100%;
            max-width: 400px;
        }
    }

    .case-study-card {
        flex-direction: column-reverse;

        &-thumbnail {
            width: 100%;
            height: 235px;

            &::before {
                display: none;
            }
        }
    }
    .swiper-slide{
        .case-study-card{
            &-thumbnail{
                height: auto;
                &::before{
                    content: '';
                    display:block;
                    padding-top: 41.72%;
                }
            }
            &-sector{
                margin-bottom: 16px;
            }
            &-text{
                h3{
                    padding-bottom: 12px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .sdv-vertical-body {
        flex-direction: column;
        gap: 0;

        .title {
            align-self: center;
            margin-bottom: 10px;

            &::before {
                left: 50%;
                transform: translate(-50%);
            }
        }

        &-items {
            max-width: 100%;
        }
    }
}

@media (max-width: 640px) {
    .clients-page {
        .hero-section-caption-wr {
            justify-content: center;
        }
    }
    .sector-gallery-section .title{
        text-align: center;
    }
}

@media (max-width: 425px) {
    .sdv-item{
        width: ac(39px, 30px, 375, 425);
    }
}

@media (max-width: 370px) {
    .sdv-item{
        width: ac(45px, 35px,320, 370);
    }
}