.meet-team-page {
    .hero-section-caption-wr{
        padding-bottom: 148px;
    }
}

.sector-hero-section-bg.sector-hero-section-bg-radial {
    div {
        &.hero-mask-bg {
            mix-blend-mode: color;
        }

        &.hero-mask-layer {
            background: radial-gradient(rgba(13, 21, 28, 0), rgba(13, 21, 28, 1));
            mix-blend-mode: normal;

        }
    }
}

.teammate-section {
    margin-top: -181px;
    padding-bottom: ac(80px, 22px);

    .title {
        margin-bottom: ac(60px, 35px);
        padding-bottom: 9px;
    }
}

.teammate-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 35px ac(35px, 15px, 1100, 1440);

    .teammate-card {
        max-width: 100%;
        width: 100%;

        &-text {
            padding: ac(34px, 24px, 1024, 1440) ac(40px, 20px, 1024, 1440) ac(36px, 20px, 1024, 1440);
        }
    }
    .teammate-card-thumbnail:before{
        padding-top: 57.46%;
    }
}
.team-mb-section{
    padding: 55px 0 ac(55px, 20px);
    .title{
        font-size: ac(30px, 24px);
        margin-bottom: ac(37px, 25px);
        font-weight: 300;
    }
 }

.team-mb-swiper-wr {
    position: relative;
    padding-bottom: 56px;
    .swiper {
        overflow: hidden;
        width: calc(100% + 20px + calc(100vw - 80.55%) / 2 - 12vw);
        @media (min-width: 1600px) {
            width: calc(100% + calc(100vw - 1280px) / 2);
        }

        &::before {
            display: none;
        }

        @media (max-width: 640px) {
            width: 100%;
            overflow: visible;
        }
    }

    .swiper-slide {
        display: flex;
        width: fit-content;
        max-width: 100%;
    }
}

.team-mb-card {
    position: relative;
    border-radius: 25px;
    border: 1.5px solid var(--light-gray);
    overflow: hidden;
    width: 268px;
    cursor: pointer;

    &-text {
        padding: 28px 30px 0;
        display: flex;
        flex-direction: column;
    }

    h3 {
        line-height: 1.2;
        font-size: ac(24px, 20px);
        margin-bottom: 8px;

    }

    h4 {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 18px;
        flex-grow: 1;
    }

    .links-wr {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 15px;
        padding-bottom: 28px;

        .linkedin-link {
            line-height: 1;
            font-size: 24px;
            transition: color 0.3s;
            color: var(--gray);

            &:hover {
                color: var(--orange);
            }
        }
        .btn{
            font-weight: normal;
        }
    }

    &-thumbnail-wr {
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            padding-top: 60.07%;
        }

        .sectors-icon {
            width: 71px;
            height: 71px;
            position: absolute;
            bottom: 20px;
            left: 30px;
            z-index: 1;
        }
    }

    &-thumbnail {
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
        }
    }

    &-text-hidden {
        display: none;
        --line-count: 8;
        --fz: 14px;
        --line-height: 1.7;
        line-height: var(--line-height);
        font-size: var(--fz);
        width: calc(100% + 15px);
        padding-right: 15px;
        overflow-y: auto;
        scrollbar-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.1);
        max-height: calc(var(--fz) * var(--line-height) * var(--line-count) + 30px);
        margin-bottom: 25px;
        scrollbar-width: 5px;


        &::-webkit-scrollbar {
            width: 4px;
            height: 2px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.5);
            border-radius: 3px;
            cursor: pointer;
        }
    }

    &.active {
        .btn span:after {
            transform: rotateX(180deg);
            display: inline-block;
        }
    }
}

@media (max-width: 1100px) {
    .teammate-grid{
        grid-template-columns: 1fr 1fr;
        max-width: 720px;
        margin: 0 auto;
    }
}

@media (max-width: 992px) {
    .teammate-grid{
     grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 800px) {
    .teammate-grid{
        grid-template-columns: 1fr;
        .teammate-card{
            max-width: 350px;
            margin: 0 auto;
        }
    }
    .team-mb-card{
        width: 307px;
    }
}

@media (max-width: 640px) {
    .meet-team-page .hero-section-caption-wr{
        justify-content: center;
        padding-bottom: 0px;
    }
    .team-mb-section .title{
        text-align: center;
    }
}