input,
textarea {
  font-family: var(--font-main);
  color: var(--black-pearl);

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: inherit !important;
  }

  &::placeholder {
    letter-spacing: 0.4px;
  }
}

.select2-container {
  width: 100% !important;
  left: 0 !important;

  * {
    border: none !important;
    border-radius: 0 !important;
  }
}

.select2-container--open .select2-dropdown {
  color: var(--black-pearl);
  overflow: hidden;
}

.select2-container--open .select2-dropdown.select2-dropdown--below {
  transform: translateY(11px);
  width: 100% !important;
}

.select2-search--dropdown {
  display: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 28px;
  padding-left: 21px;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background: var(--orange);
}

.select2-selection__arrow {
  color: var(--black-pearl);
  font-size: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  right: 5px !important;

  b {
    display: none;
  }

  &::before {
    content: "\e901";
    font-family: "icomoon";
    display: inline-block;
    transform-origin: center;
  }
}

.select2-container--open,
.select2-container--focus {
  .select2-selection__arrow {
    &::before {
      transform: rotateX(180deg) translateZ(-1px);
    }
  }
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: var(--black-pearl);
}

.drop-form-wr {
  padding: ac(39px, 25px) ac(60px, 20px) ac(49px, 20px);
  background: var(--black);
  border-radius: 25px;
  border: 1.5px solid var(--light-gray);
}

.upload-btn {
  label {
    cursor: pointer;
    transition: 0.3s;
  }

  label:hover {
    color: var(--orange);
  }
}

.drop-cv-form {
  background: #fff;
  background: var(--white);
  border: 1.5px solid var(--gray);
  border-radius: 10px;
  display: flex;
  padding: 9px 10px;
  width: 100%;
  color: var(--black);

  &-motto {
    margin-bottom: ac(36px, 18px);
    font-size: 14px;
    max-width: 350px;
    line-height: 171%;
  }

  .input-wr {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    font-size: min(max(calc(11.71429px + 0.29762vw), 14px), 16px);
    padding-left: min(max(calc(-33.14286px + 4.31548vw), 0px), 29px);
    width: 28.3%;
    border-right: 1px solid var(--black);

    &:nth-of-type(2) {
      width: 30.1%;
      padding-left: 20px;
    }
  }

  .select-wr {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    font-size: min(max(calc(11.71429px + 0.29762vw), 14px), 16px);
    width: 20%;
    border-right: 1px solid var(--black);
    position: relative;
  }

  .upload-btn {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-left: 20px;

    label {
      display: block;
    }

    .upload-status {
      width: 100%;
      display: block;
      /* white-space: nowrap;
             overflow: hidden;
             text-overflow: ellipsis;*/
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 20px;
  }

  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    right: 8px;
  }

  .select2-selection__arrow {
    font-size: 11px !important;
    right: ac(16px, 2px, 1024, 1440) !important;
    top: 4px !important;
  }

  .submit-btn {
    width: ac(45px, 40px);
    height: ac(45px, 40px);
    font-size: ac(14px, 10px);
  }
}

.alerts-section.drop-cv-section {
  @media (min-width: 993px) {
    .title {
      margin-bottom: ac(41px, 20px);
    }

    .drop-form-wr {
      padding-left: ac(55px, 20px);
    }

    .drop-cv-form {
      .input-wr {
        width: 30.9%;
        flex-grow: 0;
      }

      .select-wr {
        &:nth-of-type(2) {
          width: 24.2%;
        }

        &:nth-of-type(3) {
          width: 17.6%;
        }

        &:nth-of-type(4) {
          width: auto;
          flex-grow: 1;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .drop-cv-form {
    flex-wrap: wrap;
    padding: 10px 18px ac(10px, 18px);

    .input-wr,
    .select-wr {
      width: 100% !important;
      max-width: 100% !important;
      border: none;
      border-bottom: 1px solid var(--black);
      min-height: 58px;
      padding-left: 0 !important;
    }

    .select2-selection__arrow {
      top: 2px !important;
      right: -2px !important;
      font-size: 9px;
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
      padding-left: 0;
    }

    .select-wr {
      margin-bottom: 7px;
    }

    .upload-btn {
      padding-left: 0;
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    padding-left: 0;
  }
}

@media (max-width: 640px) {
  .drop-form-wr {
    background: rgba(13, 21, 28, 0.5);
  }
}

.select2-container--default .select2-selection--multiple {
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--black);

  position: relative;

  &::before {
    color: inherit;
    content: "\e901";
    font-family: icomoon;
    font-size: 8px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.select2-container--open {
  .select2-selection--multiple {
    &::before {
      transform: rotateX(180deg);
      top: 5px;
    }
  }
}

.select2-container .select2-search--inline .select2-search__field {
  font-size: 12px;
  height: 18px;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: none;
  border-bottom: 1px solid var(--black);
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background: none;
  border: none;
  font-size: 16px;
  padding-left: 13px;
}

.select2-selection__choice__display {
  padding-right: 0;
  display: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: flex;
  flex-wrap: wrap;
}

.select2-search {
  display: none !important;
}

.select2-placeholder {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  font-size: 16px;
  padding: 0;
  padding-left: 21px;
  padding-right: 28px;
  display: flex;
  align-items: center;
}

.select2-container--default .select2-selection--multiple {
  cursor: pointer;
}

.select2-container--default .select2-results > .select2-results__options {
  overflow-y: auto;
  scrollbar-color: var(--orange) rgba(0, 0, 0, 0.1);
  scrollbar-width: 5px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--orange);
    border-radius: 3px;
    cursor: pointer;
  }
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: ac(179px, 165px);
}
