/* ----------------------- Common desktop header ----------------------- */
.header {
    position: absolute;
    transition: background ease-in-out 0.25s, height ease-in-out 0.25s;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    min-height: 128px;
    padding: ac(0px, 15px) 0 0;
    display: flex;
    align-items: center;
    &-btn-wr{
        display: flex;
        gap: ac(20px, 10px);

    }

    .cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &.absolute {
        position: absolute;
    }

    &.fixed {
        position: fixed;
    }

    &.scrolled {
        background: rgba(var(--black-rgb), 0.8);
        backdrop-filter: blur(10px);
        height: ac(100px, 60px);

        .logo {
            width: ac(130px, 60px);
        }
    }

    .logo {
        height: 100%;
        width: 268px;
        flex-shrink: 0;
        transition: transform 0.3s;

        &::before {
            display: none;
        }

        &:hover {
            transform: scale(1.06);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }


}

.main-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: ac(80px, 65px);
    height: 100vh;
    z-index: 80;
    border-radius: 0;
    transform: translateX(calc(-100% + ac(80px, 65px)));

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: var(--black-pearl);
        opacity: 0.6;
        border-radius: inherit;
    }


    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    .navbar-nav {
        display: flex;
        width: 580px;
        max-width: 100vw;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding-left: 160px;
        transform: translateX(-100%);
        background: var(--black-pearl);
        transition: 0.5s;

        &.active {
            transform: none;
        }
    }

    .menu {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .menu-item {
        margin-right: 20px;
        position: relative;
        transition: 0.3s;

        &.dropdown {
            .dropdown-menu-link-wr {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .plus {
                position: relative;
                width: 24.7px;
                height: 24.7px;
                margin-left: 10px;
                cursor: pointer;
                transition: transform 0.2s;



                &::before, &::after {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    flex-shrink: 0;
                    content: '';
                    display: block;
                    height: 1px;
                    width: 24.7px;
                    background: var(--white);
                    transform-origin: center;
                    transition: .3s;
                }

                &::after {
                    transform: rotate(90deg);
                }
                &:hover{
                    transform: scale(1.2);
                    &::before, &::after{
                        background: var(--green);
                    }
                }
            }

            & > .menu-link {
                display: inline-flex;
                justify-content: space-between;
                align-items: center;

            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 25px;
                width: 0;
                height: 4px;
                background: var(--primary);
                transition: 0.3s;

            }

            &.active {
                padding-left: 50px;
                .dropdown-menu-link-wr{
                    .menu-link{
                        color: var(--gray);
                    }
                }

                &::before {
                    width: 40px;
                }

                .plus {
                    &::after {
                        transform: rotate(90deg);
                        opacity: 0;
                    }
                    &:hover{
                        &::before{
                            background: var(--orange);
                        }
                    }
                }
            }

        }
    }


    .menu-link {
        position: relative;
        font-size: ac(34px, 32px);
        @mixin transition-all;

        &:hover {
            color: var(--gray);
        }
    }

    .dropdown-menu {
        padding: 10px 0;
        display: none;

        li {
            opacity: 0;
            transform: translateX(-25px);
            animation: slide-left forwards 0.8s;

            .menu-link {
                padding: 5px 0;
                text-transform: capitalize;
                font-size: ac(22px, 20px);
            }
        }
    }
}

@keyframes slide-left {
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */
.menu-toggle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    max-width: 55%;
    height: 13px;
    display: flex;


    &::before,
    &::after {
        background: var(--white);
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        height: 2px;
        background: var(--white);
        transition: 0.3s;
        transform-origin: center;
    }

    &::before {
        top: 0;
        width: 50%;
    }

    &::after {
        bottom: 0;
    }

    @mixin transition-all;

    &.active {

        &::before {
            top: 0;
            transform: rotate(-45deg);
            width: 100%;
        }

        &::after {
            top: 0;
            transform: rotate(45deg);
        }
        &:hover{
            &::before, &::after{
                background: var(--orange);
            }
        }
    }
    &:hover{
        &::before{
            width: 100%;
        }
        &::before, &::after{
            background: var(--green);
        }
    }
}

.header-close-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 77;
    background: rgba(0, 0, 0, 0.7);
    pointer-events: none;
    visibility: hidden;
    opacity: 0;

    @mixin transition-all;

    &.active {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        cursor: pointer;
    }
}

/* end of HEADER COMPONENTS */

@media (--mobile-menu-start-point) {
    .header{
        z-index: 81;
        .cont{
            justify-content: center;
            .btn-contact{
                display: none;
            }
        }
    }
    .main-menu{
        width: 100%;
        height: 80px;
        transform: translateX(0);
        top: auto;
        bottom: 0;
        &::before{
            opacity: 0.8;
        }
        .navbar{
            height: 100vh;
        }
        .navbar-nav{
            transform: none;
            height: 100vh;
            width: 100%;
            max-width: unset;
            top: 100%;
            padding: 100px 36px 77px;
            align-items: center;
            &.active{
                transform: translateY(-100%);
                border-radius: 0 0 50px 0;
            }
        }
        .menu{
            height: auto;
            max-height: 100%;
            overflow-y: auto;
            justify-content: flex-start;
        }
    }
  }
@media (max-height: 555px) and (max-width: 375px){
    .header{
        min-height: 90px;
    }
    .main-menu{
        .navbar-nav{
            padding-top: 160px;
        }
    }
}

@media (max-height: 618px) and (max-width: 640px){
    .header{
        min-height: 90px;
    }
    .safari-12, .safari-13{
        .main-menu .navbar-nav{
            padding-top: 160px;
        }
    }
}