.news-page {
    .hero-section {
        padding-bottom: 38px;
        padding-top: 120px!important;

        .hero-section-caption {
            margin-top: auto;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
        }

        .hero-section-col {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-bottom: 17.2vh;

            &>p {
                &:first-child {
                    font-size: 24px;
                    line-height: 34px;
                    color: var(--orange);
                }
            }

            .btn {
                margin-top: 32px;
                font-size:ac(16px, 14px);
                letter-spacing: 0.4px;
                span{
                    padding-right: 3px;
                }
            }
        }

        .hero-section-descr {
            position: relative;
            font-size: 30px;
            line-height: 1.26;
            text-align: right;
            color: var(--light-gray);
            padding-bottom: 13px;

            &::before {
                content: '';
                position: absolute;
                bottom: -10px;
                right: 0;
                width: 40px;
                height: 4px;
                background-color: var(--orange);
            }
        }

        .hero-section-date {
            display: flex;
            align-items: center;
            margin-top: 8px;

            span {
                font-size: 14px;
                line-height: 32px;
                color: var(--white);

                &:not(:last-child) {
                    position: relative;
                    margin-right: 23px;

                    &::before {
                        content: '';
                        position: absolute;
                        right: -14px;
                        top: 14px;

                        width: 4px;
                        height: 4px;
                        background-color: var(--orange);
                        border-radius: 100%;
                    }
                }

                &:last-child {
                    color: var(--grey);
                }
            }
        }

        @mixin mob-lg {
            .hero-section-caption {
                flex-direction: column-reverse;

                .hero-section-title {
                    margin-right: auto;
                }
            }

            .hero-section-col {
                margin-bottom: 8vh;
            }
        }
    }


    .hero-form-section {
        margin-top: -32px;

        .hero-form-wr {
            width: 714px;
            margin: 0 0 -32px;
        }
        .hero-form{
            .input-wr{
                width: 43.8%;
            }
            .select-wr{

              &:nth-of-type(2){
                  width: 25%;
              }
              &:nth-of-type(3){
                  width: 32%;
              }
            }
        }
    }
        
    .drop-cv-section {
        margin-top: ac(-4px, 43px);
        .title{
            font-size: ac(30px, 26px);
            font-weight: 300;
            padding-bottom: 16px;
            margin-bottom: ac(40px, 25px);
            line-height: 128%;
        }

        .drop-form-wr {
            padding: ac(44px, 30px) ac(60px, 20px) ac(50px, 20px);
        }

        .drop-cv-form {


            .input-wr {
                width: 38.3%;
                max-width: 100%;
                input{
                    width: 100%;
                }

                &:nth-child(2) {
                    max-width: 100%;
                    flex-grow: 1;
                    margin-right: 20px;
                    border-right: none;
                }
            }
        }

        @media (max-width: 1023px) {
            .drop-cv-form {
                flex-direction: column;
                padding: 25px 20px 20px;
                position: relative;

                .input-wr {
                    border-right: 0;
                    border-bottom: 1px solid var(--black-pearl);
                    max-width: 100%;
                    padding-bottom: 17px;
                    margin-bottom: 6px;
                    min-height: 40px;
                    position: relative;

                    &:first-child {
                        max-width: 100%;
                    }

                    &:nth-child(2) {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: 0;
                        width: calc(100% - 65px);
                        margin-right: 0;
                        padding-right: 50px;
                    }
                }

                .submit-btn {
                    bottom: 20px;
                    position: absolute;
                    right: 20px;
                }
            }
        }
    }

    .news-blog {
        margin-top: 42px;

        &__sort {
            display: inline-flex;
            align-items: center;
            margin-left: auto;

            &>span {
                font-size: 14px;
                line-height: 32px;
                margin-right: 10px;
            }

            .select2-selection--single {
                background-color: transparent;
          
                .select2-selection__rendered,.select2-selection__placeholder {
                    color: var(--white);
                    font-size: 14px;
                }

                .select2-selection__arrow {
                    top: 12px;
                    width: 7px;
                    height: 7px;
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.083' height='6.577'%3E%3Cg fill='%23fff'%3E%3Cpath d='M3.041 0h1v6h-1z'/%3E%3Cpath d='m6.376 2.335.707.707-3.536 3.535-.707-.707z'/%3E%3Cpath d='m4.242 5.87-.707.707L0 3.04l.707-.707z'/%3E%3C/g%3E%3C/svg%3E") center / contain no-repeat;


                    &::before {
                        display: none;
                    } 
                }
            }

            .select2-container--open {
                .select2-selection__arrow {
                    transform: rotate(180deg);
                }
            }

        }

        &__wrapper {
            margin-top: 27px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: ac(54px, 20px, 1200, 1440) ac(74px, 20px, 1200, 1440);

            .news-card {
                width: 100%;
                max-width: 100%;
            }

            @media (max-width: 1100px) {
            }
        }
    }
    .footer {
        padding-top: ac(160px, 87px);
    }

    @media (min-width: 750px) and (max-width: 1100px) {
        .cont{
            width: 88%;
            margin: 0 auto 0 10.3vw;
        }
    }

}
@media (max-width: 750px){
    .news-page .news-blog__wrapper{
        grid-template-columns: 1fr;
        .news-card{
            max-width: 400px;
        }
    }
}





