.candidates-page {
    .hero-section-caption-wr{
        padding-bottom: ac(187px, 26px);
    }
    .mission-section{
        padding-top: ac(142px, 50px);
    }
    .drop-cv-section{
        padding-top: ac(71px, 35px);
    }
}
.sb-sectors-section{
    padding: ac(150px, 35px) 0 ac(70px, 30px);
    .title{
        padding-bottom: 9px;
        margin-bottom: 10px;
    }
}

.sb-sectors {
    display: flex;
    width: 100%;
    gap: 5px;
    min-height: 540px;
    &-item {
        writing-mode: vertical-rl;
        transform: scale(-1, -1);
        padding: 1.5px;
        background: linear-gradient(to bottom, var(--light-gray), #050D14);
        overflow: hidden;
        width: 25%;

        &:first-of-type{
            border-radius: 0 25px 0 0;
        }
        &:last-of-type{
            border-radius: 25px 0 0 0;
        }
        &-content{
            width: 100%;
            display: flex;
            align-items: center;
            background: var(--black);
            border-radius: inherit;
            padding: 90px 25px 40px;
        }
        h3{
            font-size: ac(54px, 30px);
            line-height: 120%;
        }
    }
}

.crc-section{
    padding: ac(69px, 40px) 0 ac(89px, 30px);
    .title{
        width: fit-content;
        margin: 0 auto 35px;
    }
}
.crc-list{
    display: grid;
    justify-content: space-between;
    grid-column-gap: 20px;
    grid-row-gap: ac(40px, 30px);
    grid-template-columns: repeat(4, 190px);
}
.crc-item-wr{
    width: 100%;
    max-width: ac(190px, 144px);
}
.crc-item{
    width: 100%;
    position: relative;
    transition: background 0.3s;
     display: block;
    border-radius: 50%;
    border: 1px solid var(--light-gray);
    overflow: hidden;
    cursor: pointer;
    &::before{
        content: '';
        display:block;
        padding-top: 100%;
    }
    &-content{
        position: absolute;
        left:0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 6px;
        &-hidden{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            transform: translateY(101%) scale(0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            //padding: 15px;
            font-size: ac(20px, 18px);
            transition: all 0.5s;
            text-align: center;
            text-wrap: balance;
            /*[data-simplebar]{
                width: 100%;
                --line-count:2;
                --fz: 16px;
                --line-height: 1.714;
                line-height: var(--line-height);
                font-size: var(--fz);
                max-height: calc(var(--fz) * var(--line-height) * var(--line-count));

                .simplebar-scrollbar::before {
                    background-color: var(--orange);
                    color: var(--orange);
                    opacity: 1!important;
                    left: 1px;
                    right: 1px;
                }

                .simplebar-track.simplebar-vertical{
                    background: rgba(255,255,255, 0.4)!important;
                    opacity: 1!important;
                    width: 5px;
                    border-radius: 5px;
                    right: -8px;
                }
            }*/

        }
    }
    &-logo img{
        max-width: revert;
        position: absolute;
        left: -1px;
        top: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        object-fit: cover;
    }
    h3{
        color: var(--light-gray);
        @mixin max-line-length 3;
        font-size: ac(24px, 20px);
        font-weight: normal;
        transition: all 0.5s;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px!important;
        text-align: center;
        text-wrap: balance;
    }
    &.crc-item-empty{
        cursor: auto;
    }
    &:not(.crc-item-empty):hover{
        /*background: var(--orange);*/
        h3{
            color: var(--white);
            transform: translateY(-101%) scale(0.5);
            opacity: 0;
        }
        .crc-item-content-hidden{
            opacity: 1;
            transform: none;
        }
    }
}

@media (max-width: 1100px) {
    .crc-list{
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 15px;
        flex-wrap: wrap;
    }
}
@media (max-width: 640px) {
    .candidates-page .hero-section-caption-wr{
        justify-content: center;
    }
}

@media (max-width: 550px) {
    .sb-sectors{
        flex-direction: column;
        min-height: revert;
        &-item{
            writing-mode:revert;
            transform: none;
            max-width: 100%;
            width: 100%;
            background: linear-gradient(to right,var(--light-gray),#050d14);

            &-content{
                padding: 20px 30px;
                min-height: 110px;
            }
            &:nth-of-type(1){
                border-radius: 25px 0 0 0;
            }
            &:last-of-type{
                border-radius: 0 0 0 25px
            }
        }
    }
}



@media (max-width: 370px) {
    .crc-item {
        max-width: 120px;
        h3{
            font-size: 16px;
        }
    }
}