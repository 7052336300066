.about-us-page {
    .footer {
        padding-top: ac(70px, 38px);
    }

    .hero-section-caption-wr {
        padding-bottom: ac(184px, 50px);
    }

    .latest-job-section {
        padding-top: ac(70px, 20px);
    }
}

.vision-section {
    padding: 0 0 ac(50px, 35px);
    margin-top: ac(-330px, -150px);
}

.vision-wr {
    display: flex;
    flex-direction: column;

    .video-wr {
        margin-left: auto;
        margin-right: 0;
        max-width: 78%;
        position: relative;
        z-index: 1;
    }
}

.vision-content-wr {
    background: var(--black-2);
    border-radius: 25px;
    border: 1.5px solid var(--light-gray);
    padding: 39px 18px 67px ();
    padding: 39px ac(18px, 20px) ac(67px, 44px) ac(60px, 20px);
    margin-top: ac(-73px, -30px);

    .title {
        margin-bottom: 27px;
        padding-bottom: 9px;
    }
}

.vision-content {
    display: flex;
    gap: ac(75px, 30px);

    &-caption {
        font-size: ac(20px, 18px);
        line-height: 1.5;
        max-width: 268px;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        gap: 25px 0;

        li {
            width: 33.3%;

            &:last-of-type {
                width: 45%;
            }

            h3 {
                font-size: ac(22px, 18px);
                font-weight: 600;
                margin-bottom: 9px;
            }

            p {
                color: var(--gray);
                font-size: 14px;
            }
        }
    }

    .marker-line {
        width: 50px;
        height: 6px;
        background: linear-gradient(to right, var(--black), var(--light-gray), var(--black));
        margin-bottom: 13px;
    }
}

.ab-cards-section {
    padding: ac(100px, 45px) 0 ac(80px, 35px);

    .swiper-slide {
        height: auto;
        display: flex;
    }

    .swiper:not(.swiper-initialized) {
        max-width: 1120px;
        margin: 0 auto;
        .swiper-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .swiper-slide {
                width: 49%;
                max-width: 498px;
            }
        }
    }
}

.ab-card {
    border: 1.5px solid var(--light-gray);
    border-radius: 25px;
    padding: ac(53px, 25px) ac(60px, 25px) ac(70px, 25px);
    font-size: 14px;
    line-height: 1.71;
    overflow: hidden;
    position: relative;
    width: 100%;

    &-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: #000;

        &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, rgba(13, 21, 28, 0.8), rgba(13, 21, 28, 0.2), rgba(13, 21, 28, 0.8));;
            z-index: 4;
            opacity: 0.4;
        }

        &::after {
            z-index: 3;
            opacity: 1;
            background: linear-gradient(to top, rgba(13, 21, 28, 1), rgba(13, 21, 28, 0.49), rgba(13, 21, 28, 1));;
        }

        .bg-layer {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: #000;
            mix-blend-mode: saturation;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }
    }

    h2 {
        margin-bottom: 15px;
        line-height: 1;
        font-size: ac(54px, 26px);
    }

    h3 {
        padding-bottom: 12px;
        margin-bottom: 17px;
        font-size: ac(30px, 20px);
        font-weight: 300;
    }
    &-text{
        --line-count:6;
        --fz: 14px;
        --line-height: 1.71;
        line-height: var(--line-height);
        font-size: var(--fz);
        max-height: calc(var(--fz) * var(--line-height) * var(--line-count));



        .simplebar-scrollbar::before {
            background-color: var(--orange);
            color: var(--orange);
            opacity: 1!important;
            left: 1px;
            right: 1px;
        }

        .simplebar-track.simplebar-vertical{
            background: rgba(255,255,255, 0.2)!important;
            opacity: 1!important;
            width: 5px;
            border-radius: 5px;
            right: -8px;
        }

    }

}


.become-section {
    padding: ac(86px, 35px) 0;
}

.become-tabs-body {
    display: flex;
    gap: 46px;
    align-items: flex-end;
}

.become-tabs {
    flex-grow: 1;

    &-content-rt {
        width: 46.60%;
        flex-shrink: 0;
        position: relative;
        border-radius: 25px;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            padding-top: 114.94%;
        }
    }
}

.become-tabs-item {
    position: relative;
    margin-bottom: 27px;
    line-height: 150%;

    &-thumbnail-text-wr {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: ac(50px, 20px) ac(100px, 20px) ac(50px, 20px) ac(50px, 20px);

        &::before {
            content: '';
            display: block;
            z-index: 1;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, rgba(13, 21, 28, .58), rgba(13, 21, 28, 1));
            opacity: 0.58;
        }
    }

    &-thumbnail-text {
        max-height: 100%;
        overflow-y: auto;
        scrollbar-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.1);
        scrollbar-width: 5px;
        width: calc(100% + 15px);
        padding-right: 15px;
        font-size: 14px;
        line-height: 171%;
        position: relative;
        z-index: 2;

        &::-webkit-scrollbar {
            width: 4px;
            height: 2px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.5);
            border-radius: 3px;
            cursor: pointer;
        }

        .ski-story {

            margin-bottom: 20px;
        }

        h3 {
            font-size: ac(24px, 18px);
            margin-bottom: 20px;
        }
    }

    &-thumbnail {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 0;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
        }
    }

    .border-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: 2px solid var(--gray);
        width: ac(410px, 338px, 992, 1440);
        max-width: 100%;
    }
    &:last-of-type{
        .border-bottom{
            display: none;
        }
    }

    .title-wr {
        position: relative;
        width: 100%;
        color: var(--gray);
        cursor: pointer;
        margin-bottom: 15px;
        transition: color 0.3s;

        &::before {
            content: '+';
            display: block;
            font-size: ac(54px, 30px);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(calc(-54% - ac(0px, 9px)));
            color: var(--orange);
            transition: opacity 0.3s;
            line-height: 1;
        }

        &:hover {
            color: var(--orange);
        }
    }

    .title {
        width: fit-content;
        margin-bottom: 0;
        padding-bottom: 17px;
        line-height: 1.2;
        font-size: ac(54px, 26px);
        padding-right: 20px;

        &::before {
            opacity: 0;
        }
    }

    &.active {
        .title-wr {
            color: var(--white);

            &::before {
                opacity: 0;
            }
        }

        .title {
            &::before {
                opacity: 1;
            }
        }

        .content-hidden {
            display: block;

            color: var(--gray);
        }
    }

    .content-hidden {
        display: none;
        padding-bottom: 30px;
        font-size: 14px;


        .become-tabs-item-thumbnail-wr {
            display: none;
        }
    }

    p {
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &:last-of-type {
        margin-bottom: -5px;

        .title-wr {
            margin-bottom: 0;
        }


        .content-hidden {
            padding-top: 24px;
            padding-bottom: 0;
        }
    }
}

@media (--become-sc-mobile-breakpoint) {
    .become-tabs-content-rt {
        display: none;
    }

    .become-tabs-item-thumbnail-wr {
        display: block !important;
    }

    .become-tabs-item-thumbnail {
        position: relative;
        width: 100%;
        overflow: hidden;
        border-radius: 25px;
        margin: 0 auto 30px;
        max-width: 400px;

        &::before {
            content: '';
            display: block;
            padding-top: 73.61%;
        }
    }

    .become-tabs-item-thumbnail-text-wr {
        position: relative;
        height: revert;
        padding: 0;
    }

    .become-tabs-item-thumbnail-text {
        height: auto;
        width: 100%;
        padding-right: 0;

        p {
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 10px;
            }
        }
    }

    .become-tabs-item .border-bottom {
        width: 100%;
    }
}


.diversity {
    padding: ac(65px, 35px) 0 ac(65px, 20px);

    &-wr {
        padding: ac(47px, 30px) ac(50px, 30px);
        border-radius: 25px;
        display: flex;
        gap: ac(86px, 30px);
        background: var(--black-2);
        border: 1.5px solid var(--light-gray);
    }

    .title {
        line-height: 1.2;
        color: var(--white);
        margin-bottom: 17px;
        font-size: ac(54px, 26px);
    }

    &-thumbnail {
        width: 300px;
        max-width: 30%;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
        border-radius: 25px;
        align-self: center;

        &::before {
            content: '';
            display: block;
            padding-top: 85.66%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .diversity-thumbnail-text {
        display: none;
    }

    font-size: 14px;
    color: var(--gray);

    p {
        margin-bottom: 10px;
        line-height: 171%;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}


.csr {
    padding: ac(84px, 40px) 0 0;
    position: relative;

    &-bg {
        height: auto;
        overflow: visible;
        z-index: 0;
        opacity: 0.06;
        width: 132.99%;
        left: 50%;
        transform: translate(-48.1%, -27.4%);

        &::before {
            content: '';
            display: block;
            padding-top: 26.87%;
        }

    }

    &-content {
        display: flex;
        gap: 35px 16px;
    }

    .title {
        margin-bottom: 10px;
        line-height: 1.2;
        padding-bottom: 15px;
    }

    &-caption {
        flex-shrink: 0;
        width: 50%;
        max-width: 552px;

        &-text {
            margin-bottom: 12px;
            font-size: 14px;
            line-height: 170%;
        }
    }

    &-motto {
        font-size: ac(30px, 20px);
        line-height: 128%;
        font-weight: 300;

        b {
            font-weight: inherit;
            color: var(--orange);
        }
    }

    .csr-video {
        position: relative;
        padding: ac(65px, 35px) 0;

        .video-wr {
            margin: 0 auto;
        }
    }
}

.latest-news-section-caption {
    .title {
        padding-bottom: 9px;
        margin-bottom: 12px;
    }
}

.latest-news-section-motto {
    max-width: 552px;
    margin-bottom: 36px;
    font-size: 14px;
    line-height: 170%;
}

.latest-section-bg {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: ac(668px, 328px);
    max-height: 100%;
    transform: translateY(-48%);
    z-index: -1;

    &::before {
        padding: 0;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(13, 21, 28, 1), rgba(13, 21, 28, 0.85), rgba(13, 21, 28, 1));
        z-index: 1;
    }

    img {
        position: absolute;
        left: 0;
        top: 1px;
        width: 100%;
        height: calc(100% - 2px);
        object-fit: cover;
        z-index: 0;
    }
}

.latest-news-section-about {
    .news-swiper {
        overflow: hidden;
        width: calc(100% + 20px + calc(100vw - 80.55%) / 2 - 12vw);
        @media (min-width: 1600px) {
            width: calc(100% + 20px + calc(100vw - 1280px) / 2);
        }

        &::before {
            display: none;
        }

        @media (max-width: 640px) {
            width: 100%;
        }
    }
}

.journey-section-about-page {

    padding-top: ac(68px, 40px);
    padding-bottom: ac(42px, 20px);


    .journey-text {
        justify-content: flex-start;
        gap: 14px;

        &-title {
            margin-bottom: 25px;
        }
    }

    .journey-text-description-wr {
        max-width: 550px;

        p {
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .btn{
            display: none;
        }
    }
}

@media (max-width: 992px) {
    .ab-cards-section .swiper:not(.swiper-initialized) .swiper-wrapper {
        flex-direction: column;
        gap: 30px;

        .swiper-slide {
            width: 100%;
            max-width: 498px;
            margin: 0 auto;
        }
    }

    .ab-card {
        p {
            color: var(--gray);
        }
    }

    .vision-content {
        &-caption {
            max-width: 100%;
        }

        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .vision-content-list {
        gap: 20px 15px;

        li {
            width: calc(50% - 15px);

            &:last-of-type {
                width: 100%;
            }
        }
    }

    .journey-section-about-page {
        .journey-text {
            gap: 0;

            &-caption {
                .btn {
                    display: none;
                }
            }

            &-title {
                max-width: 100%;
                font-size: 22px;
                font-weight: normal;
                line-height: 171%;
                margin-bottom: 20px;
            }

            &-description {
                .btn {
                    display: block;
                    margin-top: 25px;
                    color: var(--white);
                    font-size: 16px;
                }
            }
        }
    }

    .diversity {
        &-wr {
            flex-direction: column;
        }

        &-thumbnail {
            display: none;
        }

        &-thumbnail-text {
            display: block !important;
            max-width: 100%;
            width: 320px;
            margin: 0 auto 20px;
        }
    }

    .csr {
        &-content {
            flex-direction: column;
        }

        &-caption {
            max-width: 100%;
            width: 100%;
        }
    }

    .latest-section-bg {
        transform: translateY(1%);
        top: 0;

        img {
            object-position: 36% center;
        }
    }



}

@media (max-width: 640px) {
    .csr-motto {
        line-height: 1.9;
    }
}

@media (max-width: 450px) {
    .vision-wr .video-wr {
        max-width: 92%;
    }
    .vision-content-wr, .vision-wr .video-wr, .vision-wr .video-wr video{
        border-radius: 15px;
    }

    .video-wr {
        &::before{
            padding-top: 52.04%;
        }
    }
}

