.news-post {
    .hero-section {
        .hero-section-caption-wr {
            margin-bottom: 9.5vh;
            align-items: flex-end;
        }

        .hero-section-title {
            margin-bottom: 0;
            font-size: ac(80px, 42px);
        }

        .hero-section-motto {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            span {
                font-size: 14px;
                line-height: 32px;
                color: var(--white);

                &:not(:last-child) {
                    position: relative;
                    margin-right: 23px;

                    &::before {
                        content: '';
                        position: absolute;
                        right: -14px;
                        top: 14px;

                        width: 4px;
                        height: 4px;
                        background-color: var(--orange);
                        border-radius: 100%;
                    }
                }
            }
        }

        @media (--mobile-menu-start-point) {
            padding-bottom: 144px;

            .hero-section-caption-wr {
                justify-content: flex-end;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            .title-marker:before {
                bottom: -11px;
            }

        }
    }

    .news-text {
        margin-top: ac(116px, 8px);

        &__wrapper {
            color: var(--gray);

            h1, h2, h3, h4, h5, h6 {
                position: relative;
                color: var(--gray);
                margin-bottom: ac(31px, 35px);
    
                &:not(:first-child) {
                    margin-top: ac(35px, 23px);
                }
    
                &::before {
                    content: '';
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    width: 50px;
                    height: 6px;
                    background-color: var(--orange);
                }
            }
    
            h1, h2 {
                color: var(--white);
            }
    
            h3 {
                font-size: ac(30px, 24px);
               
            }
    
            p {
                font-size: 14px;
                line-height: 24px;
    
                &:not(:first-child) {
                    margin-top: ac(10px, 0px);
                }
            }
    
            ul, ol {
                &:not(:first-child) {
                    margin-top: ac(10px, 0px);
                }
    
                li {
                    position: relative;
                    padding-left: 15px;
                    font-size: 14px;
                    line-height: 23px;
        
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                    }
                }
            }
        
            ul {
                li {
                    &::before {
                        top: 9px;
                        left: 0;
                        width: 6px;
                        height: 6px;
                        border: 1.5px solid var(--gray);
                        border-radius: 50%;
                    }
                }
            }
        
            ol {
                  counter-reset: my-counter;
        
                li {
                    counter-increment: my-counter;
        
                    &::before {
                        content: counter(my-counter) ". ";
                        color: var(--orange);
                    }
                }
            }
        
            a {
                display: inline;
                font-size: inherit;
                line-height: inherit;
                text-decoration: underline;
        
                &:hover {
                    color: var(--orange);
                }
            }
        
        }

        &__bottom {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 36px;

            .footer-line {
                margin-bottom: 37px;
            }

            .footer-link {
                margin-right: 54px;
            }
        }
    }

    .footer {
        padding-top: ac(148px, 87px);
    }
}